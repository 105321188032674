const initialState = {
	isLoading: false,
	listmessages: [],
	totalsize: 0,
};

function messageReducer(state = initialState, action) {
    switch (action.type) {
		case 'LOADING_MESSAGE':
			return {
				...state,
				isLoading: action.payload
			};
		case 'LIST_MESSAGE':
			return {
				...state,
				listmessages: action.payload.listmessages,
				totalsize: action.payload.totalsize
			};
		case 'APPEND_MESSAGE':
			return {
				...state,
				listmessages: [...state.listmessages, action.payload],
				totalsize: +action.payload.totalsize + 1
			};
		default:
		  return state;
    }
}

export default messageReducer;
