import { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { getListConversations, syncConversationsMessages } from "actions/conversationActions";
import { getListMessages } from "actions/messageActions";
import { Link } from "react-router-dom";
import Loading from "components/Loading";
import { initSocket } from "socket";
import moment from "moment";

function Main(props) {
    const [page_conversation, setPageConversation] = useState(1);
    const [sizePerPage_conversation, setSizePerPageConversation] = useState(10);
    const [searchText_conversation, setSearchTextConversation] = useState("");
    const [totalSize_conversation, setTotalSizeConversation] = useState(0);
    const [conversationSelect, setConversationSelect] = useState(null);
    var { listconversations, totalsize: totalsize_conversation } = props.conversation;

    const [page_message, setPageMessage] = useState(1);
    const [sizePerPage_message, setSizePerPageMessage] = useState(10);
    const [searchText_message, setSearchTextMessage] = useState("");
    const [totalSize_message, setTotalSizeMessage] = useState(0);
    var { listmessages, totalsize: totalsize_message } = props.message;

    const { t } = useTranslation();
    var { getListConversations, getListMessages, syncConversationsMessages } = props;
    var isLoading = props.conversation.isLoading || props.message.isLoading;

    useEffect(() => {
        getListConversations(page_conversation, sizePerPage_conversation, searchText_conversation);
    }, [getListConversations, page_conversation, sizePerPage_conversation, searchText_conversation]);

    useEffect(() => {
        setTotalSizeConversation(totalsize_conversation);
    }, [totalsize_conversation]);

    useEffect(() => {
        if (conversationSelect) {
            getListMessages(page_message, sizePerPage_message, conversationSelect);
        }
    }, [getListMessages, page_message, sizePerPage_message, conversationSelect]);

    useEffect(() => {
        setTotalSizeMessage(totalsize_message);
    }, [totalsize_message]);

    useEffect(() => {
        initSocket();
    }, []);

    const onClickSyncConversationsMessages = () => {
        syncConversationsMessages();
    };

    return (
        <Fragment>
            <Loading isLoading={isLoading} />
            <div className="d-flex flex-column flex-lg-row">
                <div className="flex-column flex-lg-row-auto w-100 w-lg-300px w-xl-400px mb-10 mb-lg-0">
                    <div className="card card-flush">
                        <div className="card-header pt-7" id="kt_chat_contacts_header">
                            <div className="w-100 position-relative">
                                <div className="row">
                                    <div className="col-lg-9">
                                        <i className="ki-outline ki-magnifier fs-3 text-gray-500 position-absolute top-50 ms-5 translate-middle-y"></i>
                                        <input
                                            type="text"
                                            className="form-control form-control-solid px-13"
                                            name="search"
                                            placeholder="Search by username or email..."
                                        />
                                    </div>
                                    <div className="col-lg-3">
                                        <button
                                            onClick={() => {
                                                onClickSyncConversationsMessages();
                                            }}
                                            className="btn btn-primary"
                                            data-tooltip-id="my-tooltip"
                                            data-tooltip-content={t("Sync Conversations Messages")}
                                        >
                                            <i className="ki-outline ki-arrows-loop fs-2 m-0 p-0"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body pt-5" id="kt_chat_contacts_body">
                            <div
                                className="scroll-y me-n5 pe-5 h-200px h-lg-auto"
                                data-kt-scroll="true"
                                data-kt-scroll-activate="{default: false, lg: true}"
                                data-kt-scroll-max-height="auto"
                                data-kt-scroll-dependencies="#kt_header, #kt_app_header, #kt_toolbar, #kt_app_toolbar, #kt_footer, #kt_app_footer, #kt_chat_contacts_header"
                                data-kt-scroll-wrappers="#kt_content, #kt_app_content, #kt_chat_contacts_body"
                                data-kt-scroll-offset="5px"
                                style={{ maxHeight: "584px" }}
                            >
                                <div className="separator separator-dashed d-none"></div>
                                {listconversations.map((item, key) => (
                                    <div
                                        onClick={() => setConversationSelect(item._id)}
                                        className="d-flex flex-stack py-4 cursor-pointer"
                                    >
                                        <div className="d-flex align-items-center">
                                            <div className="symbol  symbol-45px symbol-circle ">
                                                <span className="symbol-label  bg-light-danger text-danger fs-6 fw-bolder ">
                                                    E
                                                </span>
                                                <div className="symbol-badge bg-success start-100 top-100 border-4 h-8px w-8px ms-n2 mt-n2"></div>
                                            </div>
                                            <div className="ms-5">
                                                <Link
                                                    to="#"
                                                    className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2"
                                                >
                                                    {item?.participants?.[1]?.nickname}
                                                </Link>
                                                <div className="fw-semibold text-muted">{item.shop_id}</div>
                                            </div>
                                        </div>
                                        <div className="d-flex flex-column align-items-end ms-2">
                                            {Math.floor(+item.updated_at / 1000)}
                                            <span className="text-muted fs-7 mb-1">
                                                {moment
                                                    .unix(
                                                        Math.floor(
                                                            +(item?.latest_message?.create_time || Date.now() / 1000)
                                                        )
                                                    )
                                                    .fromNow()}
                                            </span>
                                            <span className="badge badge-sm badge-circle badge-light-warning">
                                                {item.unread_count}
                                            </span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex-lg-row-fluid ms-lg-7 ms-xl-10">
                    <div className="card" id="kt_chat_messenger">
                        <div className="card-header" id="kt_chat_messenger_header">
                            <div className="card-title">
                                <div className="d-flex justify-content-center flex-column me-3">
                                    <Link
                                        to="#"
                                        className="fs-4 fw-bold text-gray-900 text-hover-primary me-1 mb-2 lh-1"
                                    >
                                        Brian Cox
                                    </Link>
                                    <div className="mb-0 lh-1">
                                        <span className="badge badge-success badge-circle w-10px h-10px me-1"></span>
                                        <span className="fs-7 fw-semibold text-muted">Active</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body" id="kt_chat_messenger_body">
                            <div
                                className="scroll-y me-n5 pe-5 h-300px h-lg-auto"
                                data-kt-element="messages"
                                data-kt-scroll="true"
                                data-kt-scroll-activate="{default: false, lg: true}"
                                data-kt-scroll-max-height="auto"
                                data-kt-scroll-dependencies="#kt_header, #kt_app_header, #kt_app_toolbar, #kt_toolbar, #kt_footer, #kt_app_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer"
                                data-kt-scroll-wrappers="#kt_content, #kt_app_content, #kt_chat_messenger_body"
                                data-kt-scroll-offset="5px"
                                style={{ maxHeight: "436px" }}
                            >
                                <div className="d-flex justify-content-start mb-10 ">
                                    <div className="d-flex flex-column align-items-start">
                                        <div className="d-flex align-items-center mb-2">
                                            <div className="symbol  symbol-35px symbol-circle ">
                                                <img
                                                    alt="Pic"
                                                    src={require("components/assets/media/avatars/300-25.jpg")}
                                                />
                                            </div>
                                            <div className="ms-3">
                                                <Link
                                                    to="#"
                                                    className="fs-5 fw-bold text-gray-900 text-hover-primary me-1"
                                                >
                                                    Brian Cox
                                                </Link>
                                                <span className="text-muted fs-7 mb-1">2 mins</span>
                                            </div>
                                        </div>
                                        <div
                                            className="p-5 rounded bg-light-info text-gray-900 fw-semibold mw-lg-400px text-start"
                                            data-kt-element="message-text"
                                        >
                                            {" "}
                                            How likely are you to recommend our company to your friends and family ?{" "}
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-end mb-10 ">
                                    <div className="d-flex flex-column align-items-end">
                                        <div className="d-flex align-items-center mb-2">
                                            <div className="me-3">
                                                <span className="text-muted fs-7 mb-1">5 mins</span>
                                                <Link
                                                    to="#"
                                                    className="fs-5 fw-bold text-gray-900 text-hover-primary ms-1"
                                                >
                                                    You
                                                </Link>
                                            </div>
                                            <div className="symbol  symbol-35px symbol-circle ">
                                                <img
                                                    alt="Pic"
                                                    src={require("components/assets/media/avatars/300-1.jpg")}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className="p-5 rounded bg-light-primary text-gray-900 fw-semibold mw-lg-400px text-end"
                                            data-kt-element="message-text"
                                        >
                                            {" "}
                                            Hey there, we’re just writing to let you know that you’ve been subscribed to
                                            a repository on GitHub.{" "}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer pt-4" id="kt_chat_messenger_footer">
                            <textarea
                                className="form-control form-control-flush mb-3"
                                rows="1"
                                data-kt-element="input"
                                placeholder="Type a message"
                            ></textarea>
                            <div className="d-flex flex-stack">
                                <div className="d-flex align-items-center me-2">
                                    <button
                                        className="btn btn-sm btn-icon btn-active-light-primary me-1"
                                        type="button"
                                        data-bs-toggle="tooltip"
                                        aria-label="Coming soon"
                                        data-bs-original-title="Coming soon"
                                        data-kt-initialized="1"
                                    >
                                        <i className="ki-outline ki-paper-clip fs-3"></i>
                                    </button>
                                    <button
                                        className="btn btn-sm btn-icon btn-active-light-primary me-1"
                                        type="button"
                                        data-bs-toggle="tooltip"
                                        aria-label="Coming soon"
                                        data-bs-original-title="Coming soon"
                                        data-kt-initialized="1"
                                    >
                                        <i className="ki-outline ki-exit-up fs-3"></i>
                                    </button>
                                </div>
                                <button className="btn btn-primary" type="button" data-kt-element="send">
                                    Send
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

Main.propTypes = {
    getListConversations: PropTypes.func.isRequired,
    getListMessages: PropTypes.func.isRequired,
    syncConversationsMessages: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
    conversation: state.conversation,
    message: state.message,
});
const mapDispatchToProps = {
    getListConversations,
    getListMessages,
    syncConversationsMessages,
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);
