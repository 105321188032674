import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { getProducts, deleteProduct, deleteUpdateCategory, shareProductById, duplicateProduct, changeProductByTemplate } from "actions/productActions";
import { getStaffs } from "actions/teamActions";
import { getShops } from "actions/shopActions";
import { getProductTemplate } from "actions/productTemplateAction";
import { getCategoriesProduct } from "actions/categoryProductAction";
import { ReactTable } from "components/Table";
import { ListDateType } from "helper/constant";
import { formatterCreateAt, convertTZ, subStringText, countString } from "components/Function";
import SelectBox from "components/elements/selectBox";
import Loading from "components/Loading";
import ModalMoveCategories from "./modalMoveCategories";
import ModalChangeByTemplate from "./modalChangeByTemplate";
import ModalShare from "./modalShare";
import Swal from "sweetalert2";
import { ModalSchedule } from "./modal";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";

const ProductList = ({ getProducts, deleteProduct, product, formatterCreateAt, getStaffs, subStringText, countString,  getCategoriesProduct, deleteUpdateCategory, shareProductById, duplicateProduct, getProductTemplate, productTemplate, changeProductByTemplate, getShops, shop }) => {
    const [page, setPage] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(50);
    const [searchText, setSearchText] = useState("");
    const [totalSize, setTotalSize] = useState(0);
    const [dateType, setDateType] = useState("");
    const [products_selected, setProductsSelected] = useState([]);
    const [filter, setFilter] = useState({});
    const [staffs, setStaffs] = useState([]);
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(false);
    const [productScheduleSelect, setProductScheduleSelect] = useState(null);
    // const [rowShow, setRowShow] = useState("");
    const { t } = useTranslation();
    const { list, totalsize, isLoading } = product;
    const { list: templates } = productTemplate;
    const { list: shops } = shop;

    const sizePerPageList = [
        {
            text: "10",
            value: 10,
        },
        {
            text: "50",
            value: 50,
        },
        {
            text: "100",
            value: 100,
        },
        {
            text: "150",
            value: 150,
        },
        {
            text: "200",
            value: 200,
        },
        {
            text: "All",
            value: totalsize || 50,
        },
    ];

    useEffect(() => {
        if (Object.keys(filter).length) {
            getProducts({ page, sizeperpage: sizePerPage, search: searchText, filter });
        }
    }, [getProducts, page, sizePerPage, searchText, filter]);

    useEffect(() => {
        setTotalSize(totalsize);
    }, [totalsize]);

    useEffect(() => {
        getStaffs({ sizeperpage: 100 }).then((data) => {
            setStaffs(data?.data?.map((staff) => ({ value: staff?.user?._id, label: staff.user.email })) || []);
        });
    }, [getStaffs]);

    useEffect(() => {
        getProductTemplate();
    }, [getProductTemplate]);

    useEffect(() => {
        getShops({ page: 1, sizeperpage: 1000 });
    }, [getShops]);

    useEffect(() => {
        getCategoriesProduct({ sizeperpage: 100 })
            .then((data) => {
                setCategories(data?.categoryProducts?.map((cate) => ({ value: cate?._id, label: cate.title })) || []);
            });
    }, [getCategoriesProduct]);

    useEffect(() => {
        setPage(1);
        setSizePerPage(50);

        if (dateType === "") {
            setFilter((prev) => ({
                ...prev,
                dateStart: "",
                dateEnd: "",
            }));
        }
        if (dateType === "custom") {
            let date_start = new Date();
            date_start = convertTZ(date_start);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date();
            date_end = convertTZ(date_end);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "today") {
            let date_start = new Date();
            date_start = convertTZ(date_start);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date();
            date_end = convertTZ(date_end);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "yesterday") {
            let date_start = new Date();
            date_start = convertTZ(date_start);
            date_start.setDate(date_start.getDate() - 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date_start);
            date_end.setDate(date_end.getDate());
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "this_week") {
            let date_start = new Date();
            date_start = convertTZ(date_start);
            date_start.setDate(date_start.getDate() - date_start.getDay() + 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date_start);
            date_end.setDate(date_end.getDate() + 6);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "last_week") {
            let date_start = new Date();
            date_start = convertTZ(date_start);
            date_start.setDate(date_start.getDate() - 7 - date_start.getDay() + 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date_start);
            date_end.setDate(date_end.getDate() + 6);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "this_month") {
            let date = new Date();
            date = convertTZ(date);
            let date_start = new Date(date.getFullYear(), date.getMonth(), 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "last_month") {
            let date = new Date();
            date = convertTZ(date);
            date.setMonth(date.getMonth() - 1);
            let date_start = new Date(date.getFullYear(), date.getMonth(), 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "this_year") {
            let date = new Date();
            date = convertTZ(date);
            let date_start = new Date(date.getFullYear(), 0, 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date.getFullYear(), 11, 31);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "last_year") {
            let date = new Date();
            date = convertTZ(date);
            date.setFullYear(date.getFullYear() - 1);
            let date_start = new Date(date.getFullYear(), 0, 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date.getFullYear(), 11, 31);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        // eslint-disable-next-line
    }, [dateType]);

    // const handleSchedule = (id) => {
    //     setRowShow(id)
    //     console.log(rowShow)
    //     Swal.fire(t("Success"), id, "success");

    // };
    const columns = [
        {
            dataField: "_id",
            text: "",
            sort: true,
            formatter: (cell, row, rowIndex) => rowIndex + 1,
        },
        {
            dataField: "main_images",
            text: t("Main Images"),
            sort: true,
            headerStyle: { width: "100px" },
            formatter: (cell, row) => (
                <>
                    <div className="symbol symbol-50px">
                        <img src={cell?.[0] || require("components/assets/media/misc/image.png")} alt="" />
                    </div>
                </>
            ),
        },
        {
            dataField: "title",
            text: t("Title"),
            sort: true,
            headerStyle: { width: "650px" },
            formatter: (cell) => <Fragment>
                {cell}
                <div className={cell?.length < 145 && cell?.length > 40 ? "text-success" : "text-danger"}>Total: {cell.length} <span className="text-info">{countString(cell)}</span></div>
            </Fragment>,
        },
        {
            dataField: "skus",
            text: t("Skus"),
            sort: true,
            formatter: (cell, row) => <Fragment>{cell?.length > 0 ? "VARIANTS" : "PRODUCT"}</Fragment>,
        },
        {
            dataField: "categories",
            text: t("Category"),
            sort: true,
            formatter: (cell, row) => categories?.find((v, i) => v?.value === cell)?.label || "",
        },
        {
            dataField: "msg",
            text: t("Message Upload"),
            sort: true,
        },
        {
            dataField: "createdBy",
            text: t("Created By"),
            sort: true,
            formatter: (cell, row) => staffs?.find((v, i) => v?.value === cell)?.label || "me",
        },
        {
            dataField: "created_at",
            text: t("Created At"),
            sort: true,
            formatter: (cell, row) => formatterCreateAt(cell),
        },
        {
            dataField: "-",
            text: t("Actions"),
            formatter: (cell, row, rowIndex, formatExtraData) => (
                <>
                    <Link
                        role="button"
                        to={`/admin/products/edit/${row._id}`}
                        className="btn btn-icon btn-bg-light btn-active-color-warning btn-sm me-2"
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content={t("Edit")}
                    >
                        <i className="ki-outline ki-notepad-edit fs-2"></i>
                    </Link>
                    <button
                        className="btn btn-icon btn-bg-light btn-active-color-danger btn-sm"
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content={t("Delete")}
                        onClick={() =>
                            deleteProduct(row._id).then((data) => {
                                if (data) getProducts({ page, sizeperpage: sizePerPage, search: searchText, filter });
                            })
                        }
                    >
                        <i className="ki-outline ki-trash fs-2"></i>
                    </button>
                    <button onClick={() => {
                        setProductScheduleSelect({
                            ...row,
                            page, sizeperpage: sizePerPage, search: searchText, filter
                        })
                    }} className="btn btn-icon btn-bg-light btn-active-color-warning btn-sm me-2" data-tooltip-id="my-tooltip" data-tooltip-content={t("Schedule")}>
                        <i className="ki-outline ki-calendar-add fs-2"></i>
                    </button>

                </>
            ),
        },
    ];

    const handleTableChange = (type, { page, sizePerPage, searchText }) => {
        if (type === "search") {
            setPage(1);
            setSizePerPage(50);
            setSearchText(searchText);
        } else {
            setPage(page);
            setSizePerPage(sizePerPage);
        }
    };

    const handleFilter = (e) => {
        setFilter((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };

    const handleEventDate = (start, end) => {
        setPage(1);
        setSizePerPage(50);

        let date_start = new Date(start);
        date_start.setHours(0, 0, 0, 0);

        let date_end = new Date(end);
        date_end.setHours(23, 59, 59, 999);
        setFilter((prev) => ({
            ...prev,
            dateStart: date_start.getTime(),
            dateEnd: date_end.getTime(),
        }));
    };

    const onClickDeleteProduct = () => {
        setLoading(true)
        deleteProduct(products_selected).then((data) => {
            setLoading(false)
            if (data) getProducts({ page, sizeperpage: sizePerPage, search: searchText, filter });
            return Swal.fire(t("Success"), t("Delete Success!"), "success");
        });
    };

    const onDuplicate = () => {
        setLoading(true)
        duplicateProduct(products_selected).then((data) => {
            setLoading(false)
            if (data) getProducts({ page, sizeperpage: sizePerPage, search: searchText, filter });
            return Swal.fire(t("Success"), t("Duplicate Success!"), "success");
        });
    };

    const handleMoveProduct = async (data) => {
        setLoading(true)
        await deleteUpdateCategory({ listId: products_selected, idCate: data }).then((data) => {
            setLoading(false)
            if (data) getProducts({ page, sizeperpage: sizePerPage, search: searchText, filter });
            return Swal.fire(t("Success"), t("Move Success!"), "success");
        });
    };

    const handleChangeProductByTemplate = async (data) => {
        setLoading(true)
        await changeProductByTemplate({ listId: products_selected, template_id: data }).then((data) => {
            setLoading(false)
            if (data) getProducts({ page, sizeperpage: sizePerPage, search: searchText, filter });
            return Swal.fire(t("Success"), t("Change Success!"), "success");
        });
    };

    const removeSelect = (arr, value) => {
        var i = 0;
        while (i < arr.length) {
            if (arr[i] === value) {
                arr.splice(i, 1);
            } else {
                ++i;
            }
        }
        return arr;
    };

    const onSelect = (row, isSelect) => {
        var products_selected_tmp = products_selected;
        if (isSelect) {
            products_selected_tmp.push(row._id);
        } else {
            products_selected_tmp = removeSelect(products_selected_tmp, row._id);
        }
        var new_arr = JSON.stringify(products_selected_tmp);
        setProductsSelected(JSON.parse(new_arr));
    };

    const onSelectAll = (isSelect, row) => {
        var products_selected_tmp = products_selected;
        if (isSelect) {
            products_selected_tmp = row.map((v, i) => v._id);
        } else {
            products_selected_tmp = [];
        }
        setProductsSelected(products_selected_tmp);
    };

    const selectRow = {
        mode: "checkbox",
        clickToSelect: false,
        selected: products_selected,
        onSelect: onSelect,
        onSelectAll: onSelectAll,
    };

    const onClickShareData = async (email) => {
        setLoading(true)
        await shareProductById(email, products_selected).then(async (data) => {
            if (data?.status === 200) {
                setLoading(false)
                return Swal.fire(t("Success"), t("Shared Success!"), "success");
            } else {
                setLoading(false)
                return Swal.fire(t("Error"), t("Share Failed!"), "error");
            }
        })

    }

    const groupButton = () => {
        return (
            <>
                <div className="mb-5 d-flex flex-md-row flex-column gap-2 flex-wrap w-100 w-md-auto">
                    <Link to="/admin/products/create" className="btn btn-primary btn-sm">
                        <i className="ki-outline ki-plus fs-2"></i> {t("Add Product")}
                    </Link>
                    <Link to="/admin/products/tools" className="btn btn-dark btn-sm">
                        <i className="ki-outline ki-setting fs-2"></i> {t("Product Tools")}
                    </Link>
                    <button
                        onClick={() => {
                            onClickDeleteProduct();
                        }}
                        type="button"
                        className="btn btn-danger btn-sm"
                    >
                        <i className="ki-outline ki-cross-circle fs-2"></i> {t("Delete Product Select")}
                    </button>
                    <ModalMoveCategories onSubmit={handleMoveProduct} categories={categories} />
                    <ModalShare
                        onSubmit={(data) => {
                            onClickShareData(data)
                        }}
                    />
                    <button
                        onClick={() => {
                            onDuplicate();
                        }}
                        type="button"
                        className="btn btn-warning btn-sm"
                    >
                        <i className="ki-outline ki-some-files fs-2"></i> {t("Duplicate")}
                    </button>
                    <ModalChangeByTemplate onSubmit={handleChangeProductByTemplate} templates={templates?.map((v, i) => ({ value: v?._id, label: v.title }))} />
                </div>
                <div className="mb-5 d-flex flex-md-row flex-column justify-content-between w-100 flex-wrap">
                    <div className="d-flex gap-2 mb-2">
                        <select
                            className="form-select"
                            data-control="select2"
                            data-hide-search="true"
                            data-placeholder="currency"
                            name="used"
                            onChange={handleFilter}
                        >
                            <option value="">Filter Used</option>
                            <option value="not_used">Not Used</option>
                            <option value="used">Used</option>
                        </select>
                        <SelectBox
                            options={staffs}
                            name="filter_staff"
                            value={filter.staff || ""}
                            onChange={(data) => {
                                setFilter((prev) => ({
                                    ...prev,
                                    staff: data.value || "",
                                }));
                                setPage(1);
                            }}
                        />
                        <SelectBox
                            options={categories}
                            name="filter_categories"
                            value={filter.categories || ""}
                            onChange={(data) => {
                                setFilter((prev) => ({
                                    ...prev,
                                    categories: data.value || "",
                                }));
                                setPage(1);
                            }}
                        />
                    </div>
                    <div className="d-flex gap-4">
                        <Select
                            name="dateType"
                            options={ListDateType}
                            className="al-select"
                            classNamePrefix="select"
                            value={ListDateType.filter(({ value }) => value === dateType)}
                            onChange={(e) => {
                                setDateType(e?.value || "");
                            }}
                            placeholder="Select a date ... "
                        />
                        {dateType === "custom" && filter?.dateStart && filter?.dateEnd ? (
                            <DateRangePicker
                                onCallback={handleEventDate}
                                initialSettings={{
                                    startDate: formatterCreateAt(filter.dateStart, "dmy"),
                                    endDate: formatterCreateAt(filter.dateEnd, "dmy"),
                                    showDropdowns: true,
                                    locale: { format: "DD/MM/YYYY" },
                                }}
                            >
                                <input type="text" className="form-control al-form-control-daterangepicker mr-1" />
                            </DateRangePicker>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            </>
        );
    };

    return (
        <div className="card min-w-full">
            <Loading isLoading={isLoading || loading} />
            <div className="card-body">
                <div className="al-variants-table al-max-height-table-cus">
                    <ReactTable
                        columns={columns}
                        data={list || []}
                        groupButton={groupButton}
                        handleTableChange={handleTableChange}
                        page={page}
                        sizePerPage={sizePerPage}
                        totalSize={totalSize}
                        alClassName="table-layout-inherit"
                        sizePerPageList={sizePerPageList}
                        isSelectRow={true}
                        selectRow={selectRow}
                    />
                </div>
                <ModalSchedule
                    data={productScheduleSelect}
                    shops={shops?.map((v, i) => ({ value: v?._id, label: `${v.name} (${v.note} - ${v.member_note})` }))}
                    onClose={() => setProductScheduleSelect(null)}
                    onSubmit={() => {
                        getProducts({ page, sizeperpage: sizePerPage, search: searchText, filter })
                    }}
                />
            </div>
        </div>
    );
};

const mapStateToProps = ({ product, productTemplate, shop }) => ({ product, productTemplate, shop });
const mapDispatchToProps = {
    getProducts,
    deleteProduct,
    formatterCreateAt,
    getStaffs,
    subStringText,
    getCategoriesProduct,
    deleteUpdateCategory,
    shareProductById,
    duplicateProduct,
    getProductTemplate,
    changeProductByTemplate,
    getShops,
    countString,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductList);
