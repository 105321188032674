import { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import cx from "classnames";
import { useTranslation } from "react-i18next";
// import PropTypes from "prop-types";
import { Link, NavLink } from "react-router-dom";
import { permissionComponent } from "./Function";
import { isMobile } from 'react-device-detect';
import { useLocation } from "react-router-dom";

function Navbar(props) {
    const [toggleTab, setToggleTab] = useState("");
    const { t } = useTranslation();
    var { role } = props.user.user;
    var { toggleSidebar, setToggleSidebar } = props;
    var { pathname } = useLocation();

	useEffect(() => {
        if (pathname.indexOf("/extension") > -1) {
            setToggleTab("extension");
        }
	}, [pathname]);

    const onClick = (data) => {
        if (data === toggleTab) {
            data = "";
        }
        setToggleTab(data);
    };

    return (
        <Fragment>
            <div
                id="kt_aside"
                className={cx("aside aside-dark aside-hoverable", {
                    "drawer": isMobile && toggleSidebar,
                    "drawer-start": isMobile && toggleSidebar,
                    "drawer-on": isMobile && toggleSidebar,
                })}
                data-kt-drawer="true"
                data-kt-drawer-name="aside"
                data-kt-drawer-activate="{default: true, lg: false}"
                data-kt-drawer-overlay="true"
                data-kt-drawer-width="{default:'200px', '300px': '250px'}"
                data-kt-drawer-direction="start"
                data-kt-drawer-toggle="#kt_aside_mobile_toggle"
            >
                <div className="aside-logo flex-column-auto" id="kt_aside_logo">
                    <Link to={pathname.indexOf("/extension") > -1 ? "/admin/extension-etsy" : "/admin"}>
                        <img
                            alt="Logo"
                            src={require("components/assets/media/custom/logo.png")}
                            className="h-50px logo"
                        />
                    </Link>
                    <div
                        id="kt_aside_toggle"
                        className={cx("btn btn-icon w-auto px-0 btn-active-color-primary aside-toggle me-n2", {
                            active: toggleSidebar,
                        })}
                        data-kt-toggle="true"
                        data-kt-toggle-state="active"
                        data-kt-toggle-target="body"
                        data-kt-toggle-name="aside-minimize"
                        onClick={() => setToggleSidebar(!toggleSidebar)}
                    >
                        <i className="ki-outline ki-double-left fs-1 rotate-180"></i>
                    </div>
                </div>
                <div className="aside-menu flex-column-fluid">
                    <div
                        className="hover-scroll-overlay-y"
                        id="kt_aside_menu_wrapper"
                        data-kt-scroll="true"
                        data-kt-scroll-activate="{default: false, lg: true}"
                        data-kt-scroll-height="auto"
                        data-kt-scroll-dependencies="#kt_aside_logo, #kt_aside_footer"
                        data-kt-scroll-wrappers="#kt_aside_menu"
                        data-kt-scroll-offset="0"
                        style={{ height: "888px" }}
                    >
                        <div
                            className="menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500"
                            id="#kt_aside_menu"
                            data-kt-menu="true"
                        >
                            {
                                pathname.indexOf("/extension") < 0 ?
                                    <Fragment>
                                        <div className="menu-item">
                                            <div className="menu-content">
                                                {/* <span className="menu-heading fw-bold text-uppercase fs-7">Pages</span> */}
                                            </div>
                                        </div>
                                        {role === 1 && (
                                            <div className="menu-item">
                                                <NavLink className="menu-link" to="/admin/manager">
                                                    <span className="menu-icon">
                                                        <i className="ki-outline ki-rocket fs-2"></i>
                                                    </span>
                                                    <span className="menu-title">Admin</span>
                                                </NavLink>
                                            </div>
                                        )}
                                        {permissionComponent({
                                            user: props.user.user,
                                            permission: "USER_REAL",
                                            component: (
                                                <div className="menu-item">
                                                    <NavLink className="menu-link" to="/admin/users-staff">
                                                        <span className="menu-icon">
                                                            <i className="ki-outline ki-address-book fs-2"></i>
                                                        </span>
                                                        <span className="menu-title">Users Staff</span>
                                                    </NavLink>
                                                </div>
                                            ),
                                        })}
                                        {permissionComponent({
                                            user: props.user.user,
                                            permission: "GET_PRODUCT",
                                            component: (
                                                <div
                                                    data-kt-menu-trigger="click"
                                                    className={cx("menu-item menu-accordion", { show: toggleTab === "spyproduct" })}
                                                >
                                                    <span className="menu-link" onClick={() => onClick("spyproduct")}>
                                                        <span className="menu-icon">
                                                            <i className="ki-outline ki-satellite fs-2"></i>
                                                        </span>
                                                        <span className="menu-title">Spy Tools</span>
                                                        <span className="menu-arrow"></span>
                                                    </span>
                                                    <div className="menu-sub menu-sub-accordion">
                                                        <div className="menu-item">
                                                            <NavLink className="menu-link" to="/admin/spy/etsy">
                                                                <span className="menu-icon">
                                                                    <i className="ki-outline ki-technology-4 fs-2"></i>
                                                                </span>
                                                                <span className="menu-title">Etsy Tool</span>
                                                            </NavLink>
                                                        </div>
                                                        <div className="menu-item">
                                                            <NavLink className="menu-link" to="/admin/spy/amazon">
                                                                <span className="menu-icon">
                                                                    <i className="ki-outline ki-technology-2 fs-2"></i>
                                                                </span>
                                                                <span className="menu-title">Amazon Tool</span>
                                                            </NavLink>
                                                        </div>
                                                        <div className="menu-item">
                                                            <NavLink className="menu-link" to="/admin/spy/tiktokv1">
                                                                <span className="menu-icon">
                                                                    <i className="ki-outline ki-tiktok fs-2"></i>
                                                                </span>
                                                                <span className="menu-title">TikTok Tool</span>
                                                            </NavLink>
                                                        </div>
                                                        <div className="menu-item">
                                                            <NavLink className="menu-link" to="/admin/spy/suggests">
                                                                <span className="menu-icon">
                                                                    <i className="ki-outline ki-night-day fs-2"></i>
                                                                </span>
                                                                <span className="menu-title">Suggests</span>
                                                            </NavLink>
                                                        </div>
                                                        <div className="menu-item">
                                                            <NavLink className="menu-link" to="/admin/spy/tiktok">
                                                                <span className="menu-icon">
                                                                    <i className="ki-outline ki-tiktok fs-2"></i>
                                                                </span>
                                                                <span className="menu-title">TikTok Product</span>
                                                            </NavLink>
                                                        </div>
                                                    </div>
                                                </div>
                                            ),
                                        })}
                                        {permissionComponent({
                                            user: props.user.user,
                                            permission: "GET_ORDER",
                                            component: (
                                                <div className="menu-item">
                                                    <NavLink className="menu-link" to="/admin/orders">
                                                        <span className="menu-icon">
                                                            <i className="ki-outline ki-handcart fs-2"></i>
                                                        </span>
                                                        <span className="menu-title">{t("Orders")}</span>
                                                    </NavLink>
                                                </div>
                                            ),
                                        })}
                                        {permissionComponent({
                                            user: props.user.user,
                                            permission: "GET_PRODUCT",
                                            component: (
                                                <div
                                                    data-kt-menu-trigger="click"
                                                    className={cx("menu-item menu-accordion", { show: toggleTab === "product" })}
                                                >
                                                    <span className="menu-link" onClick={() => onClick("product")}>
                                                        <span className="menu-icon">
                                                            <i className="ki-outline ki-abstract-26 fs-2"></i>
                                                        </span>
                                                        <span className="menu-title">Products</span>
                                                        <span className="menu-arrow"></span>
                                                    </span>
                                                    <div className="menu-sub menu-sub-accordion">
                                                        <div className="menu-item">
                                                            <NavLink className="menu-link" to="/admin/Products">
                                                                <span className="menu-icon">
                                                                    <i className="ki-outline ki-category fs-2"></i>
                                                                </span>
                                                                <span className="menu-title">Product Systems</span>
                                                            </NavLink>
                                                        </div>
                                                        <div className="menu-item">
                                                            <NavLink className="menu-link" to="/admin/product-schedule">
                                                                <span className="menu-icon">
                                                                    <i className="ki-outline ki-calendar-tick fs-2"></i>
                                                                </span>
                                                                <span className="menu-title">Schedule Upload</span>
                                                            </NavLink>
                                                        </div>
                                                        <div className="menu-item">
                                                            <NavLink className="menu-link" to="/admin/product-template">
                                                                <span className="menu-icon">
                                                                    <i className="ki-outline ki-code fs-2"></i>
                                                                </span>
                                                                <span className="menu-title">Template</span>
                                                            </NavLink>
                                                        </div>
                                                        <div className="menu-item">
                                                            <NavLink className="menu-link" to="/admin/categories">
                                                                <span className="menu-icon">
                                                                    <i className="ki-outline ki-microsoft fs-2"></i>
                                                                </span>
                                                                <span className="menu-title">Category Product</span>
                                                            </NavLink>
                                                        </div>
                                                        <div className="menu-item">
                                                            <NavLink className="menu-link" to="/admin/product-tiktoks">
                                                                <span className="menu-icon">
                                                                    <i className="ki-outline ki-tiktok fs-2"></i>
                                                                </span>
                                                                <span className="menu-title">Product TikToks</span>
                                                            </NavLink>
                                                        </div>
                                                        <div className="menu-item">
                                                            <NavLink className="menu-link" to="/admin/product-freeze">
                                                                <span className="menu-icon">
                                                                    <i className="ki-outline ki-cross-square fs-2"></i>
                                                                </span>
                                                                <span className="menu-title">Product Freeze</span>
                                                            </NavLink>
                                                        </div>
                                                    </div>
                                                </div>
                                            ),
                                        })}

                                        {permissionComponent({
                                            user: props.user.user,
                                            permission: "GET_PROMOTION",
                                            component: (
                                                <div
                                                    data-kt-menu-trigger="click"
                                                    className={cx("menu-item menu-accordion", { show: toggleTab === "promotions" })}
                                                >
                                                    <span className="menu-link" onClick={() => onClick("promotions")}>
                                                        <span className="menu-icon">
                                                            <i className="ki-outline ki-discount fs-2"></i>
                                                        </span>
                                                        <span className="menu-title">Promotions</span>
                                                        <span className="menu-arrow"></span>
                                                    </span>
                                                    <div className="menu-sub menu-sub-accordion">
                                                        <div className="menu-item">
                                                            <NavLink className="menu-link" to="/admin/promotions">
                                                                <span className="menu-icon">
                                                                    <i className="ki-outline ki-tag fs-2"></i>
                                                                </span>
                                                                <span className="menu-title">Setup</span>
                                                            </NavLink>
                                                        </div>
                                                        <div className="menu-item">
                                                            <NavLink className="menu-link" to="/admin/promotions-logs">
                                                                <span className="menu-icon">
                                                                    <i className="ki-outline ki-cheque fs-2"></i>
                                                                </span>
                                                                <span className="menu-title">Logs</span>
                                                            </NavLink>
                                                        </div>
                                                    </div>
                                                </div>
                                            ),
                                        })}
                                        {permissionComponent({
                                            user: props.user.user,
                                            permission: "USER_REAL",
                                            component: (
                                                <div className="menu-item">
                                                    <NavLink className="menu-link" to="/admin/shops">
                                                        <span className="menu-icon">
                                                            <i className="ki-outline ki-shop fs-2"></i>
                                                        </span>
                                                        <span className="menu-title">Shops</span>
                                                    </NavLink>
                                                </div>
                                            ),
                                        })}
                                        {role === 1 && (
                                            <div className="menu-item">
                                                <NavLink className="menu-link" to="/admin/messages">
                                                    <span className="menu-icon">
                                                        <i className="ki-outline ki-message-text-2 fs-2"></i>
                                                    </span>
                                                    <span className="menu-title">Messages</span>
                                                </NavLink>
                                            </div>
                                        )}
                                        {permissionComponent({
                                            user: props.user.user,
                                            permission: "USER_REAL",
                                            component: (
                                                <div className="menu-item">
                                                    <NavLink className="menu-link" to="/admin/packages">
                                                        <span className="menu-icon">
                                                            <i className="ki-outline ki-package fs-2"></i>
                                                        </span>
                                                        <span className="menu-title">Packages</span>
                                                    </NavLink>
                                                </div>
                                            ),
                                        })}
                                        {permissionComponent({
                                            user: props.user.user,
                                            permission: "USER_REAL",
                                            component: (
                                                <div
                                                    data-kt-menu-trigger="click"
                                                    className={cx("menu-item menu-accordion", { show: toggleTab === "returncancel" })}
                                                >
                                                    <span className="menu-link" onClick={() => onClick("returncancel")}>
                                                        <span className="menu-icon">
                                                            <i className="ki-outline ki-arrows-circle fs-2"></i>
                                                        </span>
                                                        <span className="menu-title">Return - Refund - Cancel</span>
                                                        <span className="menu-arrow"></span>
                                                    </span>
                                                    <div className="menu-sub menu-sub-accordion">
                                                        <div className="menu-item">
                                                            <NavLink className="menu-link" to="/admin/returns">
                                                                <span className="menu-icon">
                                                                    <i className="ki-outline ki-call fs-2"></i>
                                                                </span>
                                                                <span className="menu-title">Returns</span>
                                                            </NavLink>
                                                        </div>
                                                        <div className="menu-item">
                                                            <NavLink className="menu-link" to="/admin/refunds">
                                                                <span className="menu-icon">
                                                                    <i className="ki-outline ki-information fs-2"></i>
                                                                </span>
                                                                <span className="menu-title">Refunds</span>
                                                            </NavLink>
                                                        </div>
                                                        <div className="menu-item">
                                                            <NavLink className="menu-link" to="/admin/cancel">
                                                                <span className="menu-icon">
                                                                    <i className="ki-outline ki-cross-circle fs-2"></i>
                                                                </span>
                                                                <span className="menu-title">Cancels</span>
                                                            </NavLink>
                                                        </div>
                                                    </div>
                                                </div>
                                            ),
                                        })}
                                        {permissionComponent({
                                            user: props.user.user,
                                            permission: "USER_REAL",
                                            component: (
                                                <div
                                                    data-kt-menu-trigger="click"
                                                    className={cx("menu-item menu-accordion", { show: toggleTab === "finances" })}
                                                >
                                                    <span className="menu-link" onClick={() => onClick("finances")}>
                                                        <span className="menu-icon">
                                                            <i className="ki-outline ki-cheque fs-2"></i>
                                                        </span>
                                                        <span className="menu-title">Finances</span>
                                                        <span className="menu-arrow"></span>
                                                    </span>
                                                    <div className="menu-sub menu-sub-accordion">
                                                        <div className="menu-item">
                                                            <NavLink className="menu-link" to="/admin/finances/statements">
                                                                <span className="menu-icon">
                                                                    <i className="ki-outline ki-lots-shopping fs-2"></i>
                                                                </span>
                                                                <span className="menu-title">Statements</span>
                                                            </NavLink>
                                                        </div>
                                                        <div className="menu-item">
                                                            <NavLink className="menu-link" to="/admin/finances/withdrawals">
                                                                <span className="menu-icon">
                                                                    <i className="ki-outline ki-exit-up fs-2"></i>
                                                                </span>
                                                                <span className="menu-title">Withdrawals</span>
                                                            </NavLink>
                                                        </div>
                                                        <div className="menu-item">
                                                            <NavLink className="menu-link" to="/admin/finances/payments">
                                                                <span className="menu-icon">
                                                                    <i className="ki-outline ki-bank fs-2"></i>
                                                                </span>
                                                                <span className="menu-title">Payments</span>
                                                            </NavLink>
                                                        </div>
                                                    </div>
                                                </div>
                                            ),
                                        })}
                                        {permissionComponent({
                                            user: props.user.user,
                                            permission: "USER_REAL",
                                            component: (
                                                <div
                                                    data-kt-menu-trigger="click"
                                                    className={cx("menu-item menu-accordion", { show: toggleTab === "designed" })}
                                                >
                                                    <span className="menu-link" onClick={() => onClick("designed")}>
                                                        <span className="menu-icon">
                                                            <i className="ki-outline ki-design fs-2"></i>
                                                        </span>
                                                        <span className="menu-title">Design</span>
                                                        <span className="menu-arrow"></span>
                                                    </span>
                                                    <div className="menu-sub menu-sub-accordion">
                                                        <div className="menu-item">
                                                            <NavLink className="menu-link" to="/admin/design/manage">
                                                                <span className="menu-icon">
                                                                    <i className="ki-outline ki-design-2 fs-2"></i>
                                                                </span>
                                                                <span className="menu-title">Manage</span>
                                                            </NavLink>
                                                        </div>
                                                        <div className="menu-item">
                                                            <NavLink className="menu-link" to="/admin/design/buy">
                                                                <span className="menu-icon">
                                                                    <i className="ki-outline ki-cheque fs-2"></i>
                                                                </span>
                                                                <span className="menu-title">Buy Design</span>
                                                            </NavLink>
                                                        </div>
                                                    </div>
                                                </div>
                                            ),
                                        })}
                                        {permissionComponent({
                                            user: props.user.user,
                                            permission: "GET_PRODUCT",
                                            component: (
                                                <div className="menu-item">
                                                    <NavLink className="menu-link" to="/admin/notification">
                                                        <span className="menu-icon">
                                                            <i className="ki-outline ki-notification-on fs-2"></i>
                                                        </span>
                                                        <span className="menu-title">Notification</span>
                                                    </NavLink>
                                                </div>
                                            ),
                                        })}
                                    </Fragment>
                                : ""
                            }
                            {
                                pathname.indexOf("/extension") > -1 ?
                                    <div data-kt-menu-trigger="click" className={cx("menu-item menu-accordion", { show: toggleTab === "extension" })} >
                                        <span className="menu-link" onClick={() => onClick("extension")}>
                                            <span className="menu-icon">
                                                <i className="ki-outline ki-element-plus fs-2"></i>
                                            </span>
                                            <span className="menu-title">Extension</span>
                                            <span className="menu-arrow"></span>
                                        </span>
                                        <div className="menu-sub menu-sub-accordion">
                                            <div className="menu-item">
                                                <NavLink className="menu-link" to="/admin/extension-etsy">
                                                    <span className="menu-icon">
                                                        <i className="ki-outline ki-tag fs-2"></i>
                                                    </span>
                                                    <span className="menu-title">Etsy</span>
                                                </NavLink>
                                            </div>
                                            <div className="menu-item">
                                                <NavLink className="menu-link" to="/admin/extension-amazon">
                                                    <span className="menu-icon">
                                                        <i className="ki-outline ki-technology-2 fs-2"></i>
                                                    </span>
                                                    <span className="menu-title">Amazon</span>
                                                </NavLink>
                                            </div>
                                            <div className="menu-item">
                                                <NavLink className="menu-link" to="/admin/extension-ebay">
                                                    <span className="menu-icon">
                                                        <i className="ki-outline ki-shop fs-2"></i>
                                                    </span>
                                                    <span className="menu-title">Ebay</span>
                                                </NavLink>
                                            </div>
                                            <div className="menu-item">
                                                <NavLink className="menu-link" to="/admin/extension-pinterest">
                                                    <span className="menu-icon">
                                                        <i className="ki-outline ki-picture fs-2"></i>
                                                    </span>
                                                    <span className="menu-title">Pinterest</span>
                                                </NavLink>
                                            </div>
                                            <div className="menu-item">
                                                <NavLink className="menu-link" to="/admin/extension-google">
                                                    <span className="menu-icon">
                                                        <i className="ki-outline ki-google fs-2"></i>
                                                    </span>
                                                    <span className="menu-title">Google shopping</span>
                                                </NavLink>
                                            </div>
                                            <div className="menu-item">
                                                <NavLink className="menu-link" to="/admin/extension-tiktok">
                                                    <span className="menu-icon">
                                                        <i className="ki-outline ki-tiktok fs-2"></i>
                                                    </span>
                                                    <span className="menu-title">Tiktok</span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                : ""
                            }
                        </div>
                    </div>
                </div>
            </div>
            {isMobile && toggleSidebar && (<div style={{ "zIndex": "109" }} className="drawer-overlay" onClick={() => setToggleSidebar(!toggleSidebar)}></div>)}
        </Fragment>
    );
}

// Navbar.propTypes = {
// };
const mapStateToProps = (state) => ({
    user: state.user,
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
