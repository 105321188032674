import axios from "axios";
import Swal from "sweetalert2";
import { tokenConfig } from "./websiteActions";
import { getListMessages } from "./messageActions";
import { t } from "i18next";


const getListConversations = (page = 0, sizeperpage = 0, search = "") => (dispatch, getState) => {
	dispatch({ type: 'LOADING_CONVERSATION', payload: true });
	var param_search = "";
	if (search !== "") {
		param_search = `&search=${search}`;
	}
	axios.get(`/api/conversation/list?page=${page}&sizeperpage=${sizeperpage}${param_search}`, tokenConfig(getState)).then(res => {
		dispatch({ type: 'LOADING_CONVERSATION', payload: false });
		if (res.data.status === 200) {
			dispatch({
				type: 'LIST_CONVERSATION',
				payload: res.data.data
			});
		} else {
			Swal.fire(t("Error"), t(res.data.message), 'error');
		}
	}).catch(err => {
		dispatch({ type: 'LOADING_CONVERSATION', payload: false });
		Swal.fire(t("Error"), t("An error occurred!"), 'error');
	});
};

const syncConversationsMessages = (data) => (dispatch, getState) => {
	Swal.fire({
		title: t("Are you sure?"),
		text: t("Are you sure to sync conversations messages?"),
		icon: 'warning',
		showCancelButton: true,
		confirmButtonColor: '#3085d6',
		cancelButtonColor: '#d33',
		confirmButtonText: t("Sure"),
		cancelButtonText: t("Cancel")
	}).then((result) => {
		if (result.value) {
			dispatch({type: 'LOADING_CONVERSATION', payload: true});
			axios.get('/api/conversation/sync', tokenConfig(getState)).then(res => {
				dispatch({type: 'LOADING_CONVERSATION', payload: false});
				if (res.data.status === 200) {
					dispatch(getListConversations());
					dispatch(getListMessages());
					Swal.fire(t("Success"), t(res.data.message), 'success');
				} else {
					Swal.fire(t("Error"), t(res.data.message), 'error');
				}
			}).catch(err => {
				dispatch({type: 'LOADING_CONVERSATION', payload: false});
                Swal.fire(t("Error"), t("An error occurred!"), 'error');
			});
		}
	})
};

export {
	getListConversations,
	syncConversationsMessages,
};
