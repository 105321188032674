import { Fragment, useState } from "react";
import { connect } from "react-redux";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { useTranslation } from "react-i18next";


function ModalAddImages({ onSubmit, rowIndex }) {
    const [isOpen, setIsOpen] = useState(false);
    const [images, setImages] = useState("");
    const { t } = useTranslation();

    const handleSubmit = () => {
        onSubmit(rowIndex, images?.split('\n') || []);
        setIsOpen(false);
    };

    return (
        <Fragment>
            <button
                className="btn btn-icon btn-bg-light btn-active-color-danger btn-sm"
                data-tooltip-id="my-tooltip"
                data-tooltip-content={t("Add images")}
                onClick={() => { setImages(""); setIsOpen(true) }}
            >
                <i className="ki-outline ki-picture fs-2"></i>
            </button>
            <Modal
                centered={true}
                size="lg"
                toggle={() => {
                    setIsOpen(!isOpen);
                }}
                isOpen={isOpen}
            >
                <div className="modal-header">
                    <h2 className="mb-0 text-info">{t("Add images")}</h2>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                        <i
                            className="ki-outline ki-cross fs-1"
                            onClick={() => {
                                setIsOpen(!isOpen);
                            }}
                        ></i>
                    </div>
                </div>
                <ModalBody>
                    <div className="d-flex justify-content-between mb-5 w-100 flex-column gap-4">
                        <div className="form-group">
                            <label>Images</label>
                            <div className="mb-5">
                                <textarea
                                    className="form-control"
                                    type="text"
                                    name="images"
                                    placeholder="https://image.com/1.png
https://image.com/2.jpg
https://image.com/3.gif
..."
                                    rows={5}
                                    defaultValue={images}
                                    onChange={(e) => {
                                        setImages(e.target.value);
                                    }}
                                />
                            </div>
                            <div className="row">
                                {images?.split("\n").length > 0 &&
                                    images?.split("\n")?.map((v, i) => (
                                            <div key={i} className="col-3">
                                                <img className="responsive p-2" alt="" src={v} width={200} />
                                            </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button
                        type="reset"
                        id="kt_modal_users_search_reset"
                        data-bs-dismiss="modal"
                        className="btn btn-active-light me-3"
                        onClick={() => {
                            setIsOpen(!isOpen);
                        }}
                    >
                        {t("Cancel")}
                    </button>
                    <button
                        onClick={handleSubmit}
                        type="submit"
                        id="kt_modal_users_search_submit"
                        className="btn btn-primary"
                    >
                        {t("Add images")}
                    </button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ModalAddImages);
