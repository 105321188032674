import { Fragment, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { getBuyLabel } from "actions/fulfillmentActions";
import Loading from "components/Loading";
import {
    Modal,
    ModalBody,
    ModalFooter
} from "reactstrap";

function Modalbuylabel(props) {
    const [_id] = useState(props.buylabel._id);
    const [dimension_length, setDimensionLength] = useState(0);
    const [dimension_width, setDimensionWidth] = useState(0);
    const [dimension_height, setDimensionHeight] = useState(0);
    const [dimension_unit, setDimensionUnit] = useState("");
    const [weight_value, setWeightValue] = useState(0);
    const [weight_unit, setWeightUnit] = useState("");
    var { isLoading } = props.order;
    var { isOpenModalBuyLabel, openModal, getBuyLabel } = props;
    const { t } = useTranslation();

    const onClickGetBuyLabel = (type) => {
        getBuyLabel({ _id, type, dimension_length, dimension_width, dimension_height, dimension_unit, weight_value, weight_unit, page: props.buylabel.page, sizePerPage: props.buylabel.sizePerPage, searchText: props.buylabel.searchText, filter_printer: props.buylabel.filter_printer, filter_shop_code: props.buylabel.filter_shop_code, filter_status: props.buylabel.filter_status, filter_work_status: props.buylabel.filter_work_status }, openModal);
    };

    const onChange = (e) => {
        if (e.target.name === "dimension_length") {
            setDimensionLength(e.target.value);
        }
        if (e.target.name === "dimension_width") {
            setDimensionWidth(e.target.value);
        }
        if (e.target.name === "dimension_height") {
            setDimensionHeight(e.target.value);
        }
        if (e.target.name === "dimension_unit") {
            setDimensionUnit(e.target.value);
        }
        if (e.target.name === "weight_value") {
            setWeightValue(e.target.value);
        }
        if (e.target.name === "weight_unit") {
            setWeightUnit(e.target.value);
        }
    }

    const onOpenModal = () => {
        openModal();
    };

    const onKeyPress = (e) => {
        if ((/^[0-9.]+$/).test(e)) {
            e.preventDefault();
        }
    }

    return (
        <Fragment>
            <Modal centered={true} size="lg" toggle={() => { onOpenModal() }} isOpen={isOpenModalBuyLabel}>
                <Loading isLoading={isLoading} />
                <div className="modal-header">
                    <h1 className="mb-0 text-dark">{t("Buy label")}</h1>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                        <i className="ki-outline ki-cross fs-1" onClick={() => { onOpenModal() }} ></i>
                    </div>
                </div>
                <ModalBody>
                        <div className="d-flex gap-2">
                            <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                                <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                    <span className="">{t("Dimension Length")}</span>
                                </label>
                                <input type="number" className="form-control" value={dimension_length} placeholder="" name="dimension_length" onChange={(e) => { onChange(e) }} onKeyPress={(e) => {onKeyPress(e)}} />
                            </div>
                            <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                                <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                    <span className="">{t("Dimension Width")}</span>
                                </label>
                                <input type="number" className="form-control" value={dimension_width} placeholder="" name="dimension_width" onChange={(e) => { onChange(e) }} onKeyPress={(e) => {onKeyPress(e)}} />
                            </div>
                            <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                                <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                    <span className="">{t("Dimension Height")}</span>
                                </label>
                                <input type="number" className="form-control" value={dimension_height} placeholder="" name="dimension_height" onChange={(e) => { onChange(e) }} onKeyPress={(e) => {onKeyPress(e)}} />
                            </div>
                            <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                                <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                    <span className="">{t("Dimension Unit")}</span>
                                </label>
                                <select className="form-select" data-control="select2" data-hide-search="true" data-placeholder="Select a unit" value={dimension_unit} name="dimension_unit" onChange={(e) => { onChange(e) }} >
                                    <option value="">{t("-- Select a unit --")}</option>
                                    <option value="CM">CM</option>
                                    <option value="INCH">INCH</option>
                                </select>
                            </div>
                        </div>
                        <div className="d-flex gap-2">
                            <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                                <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                    <span className="">{t("Weight Value")}</span>
                                </label>
                                <input type="number" className="form-control" value={weight_value} placeholder="" name="weight_value" onChange={(e) => { onChange(e) }} onKeyPress={(e) => {onKeyPress(e)}} />
                            </div>
                            <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                                <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                    <span className="">{t("Weight Unit")}</span>
                                </label>
                                <select className="form-select" data-control="select2" data-hide-search="true" data-placeholder="Select a unit" value={weight_unit} name="weight_unit" onChange={(e) => { onChange(e) }} >
                                    <option value="">{t("-- Select a unit --")}</option>
                                    <option value="GRAM">GRAM</option>
                                    <option value="POUND">POUND</option>
                                </select>
                            </div>
                        </div>
                </ModalBody>
                <ModalFooter>
                    <button type="reset" id="kt_modal_users_search_reset" data-bs-dismiss="modal" className="btn btn-active-light me-3" onClick={() => { onOpenModal() }} >{t("Cancel")}</button>
                    <button type="submit" id="kt_modal_users_search_submit" className="btn btn-primary me-3" onClick={() => { onClickGetBuyLabel("noskip") }}  >{t("Buy Label")}</button>
                    <button type="submit" id="kt_modal_users_search_submit" className="btn btn-success" onClick={() => { onClickGetBuyLabel("skip") }}  >{t("Skip & Buy Label")}</button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
}

Modalbuylabel.propTypes = {
    getBuyLabel: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
    order: state.order
});
const mapDispatchToProps = {
    getBuyLabel,
}

export default connect(mapStateToProps, mapDispatchToProps)(Modalbuylabel);