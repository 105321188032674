import axios from "axios";
import Swal from "sweetalert2";
import { tokenConfig } from "./websiteActions";
import { t } from "i18next";


const getListMessages = (page = 0, sizeperpage = 0, conversationId = "") => (dispatch, getState) => {
	dispatch({ type: 'LOADING_MESSAGE', payload: true });
	var param_search = "";
	// if (search !== "") {
	// 	param_search = `&search=${search}`;
	// }
	axios.get(`/api/message/list?conversationId=${conversationId}&page=${page}&sizeperpage=${sizeperpage}${param_search}`, tokenConfig(getState)).then(res => {
		dispatch({ type: 'LOADING_MESSAGE', payload: false });
		if (res.data.status === 200) {
			dispatch({
				type: 'LIST_MESSAGE',
				payload: res.data.data
			});
		} else {
			Swal.fire(t("Error"), t(res.data.message), 'error');
		}
	}).catch(err => {
		dispatch({ type: 'LOADING_MESSAGE', payload: false });
		Swal.fire(t("Error"), t("An error occurred!"), 'error');
	});
};

export {
	getListMessages,
};
