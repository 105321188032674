import axios from "axios";
import Swal from "sweetalert2";
import { tokenConfig } from "./websiteActions";
import { getProductsByShops } from "./productActions";
import { t } from "i18next";
import _ from 'lodash';
import { sleep } from "components/Function";


const getListPromotions = (page = 0, sizeperpage = 0, search = "") => (dispatch, getState) => {
	dispatch({ type: 'LOADING_PROMOTION', payload: true });
	var param_search = "";
	if (search !== "") {
		param_search = `&search=${search}`;
	}
	axios.get(`/api/promotion/list?page=${page}&sizeperpage=${sizeperpage}${param_search}`, tokenConfig(getState)).then(res => {
		dispatch({ type: 'LOADING_PROMOTION', payload: false });
		if (res.data.status === 200) {
			dispatch({
				type: 'LIST_PROMOTION',
				payload: res.data.data
			});
		} else {
			Swal.fire(t("Error"), t(res.data.message), 'error');
		}
	}).catch(err => {
		dispatch({ type: 'LOADING_PROMOTION', payload: false });
		Swal.fire(t("Error"), t("An error occurred!"), 'error');
	});
};

const saveAddPromotion = (data, closemodal) => (dispatch, getState) => {
	var body = {
		title: data.title,
		product_level: data.product_level,
		activity_type: data.activity_type,
		activity_price_amount_first_variant: data.activity_price_amount_first_variant,
		limit_first_varinat: data.limit_first_varinat,
		activity_price_amount: data.activity_price_amount,
		discount_period: data.discount_period,
		auto_renews: data.auto_renews,
		// begin_time: data.begin_time,
		// end_time: data.end_time
	};
	Swal.fire({
		title: t("Are you sure?"),
		text: t("Are you sure to add promotion?"),
		icon: 'warning',
		showCancelButton: true,
		confirmButtonColor: '#3085d6',
		cancelButtonColor: '#d33',
		confirmButtonText: t("Sure"),
		cancelButtonText: t("Cancel")
	}).then((result) => {
		if (result.value) {
			dispatch({ type: 'LOADING_PROMOTION', payload: true });
			axios.post('/api/promotion/add', body, tokenConfig(getState)).then(res => {
				dispatch({ type: 'LOADING_PROMOTION', payload: false });
				if (res.data.status === 200) {
					closemodal();
					dispatch(getListPromotions(1, 10));
					Swal.fire(t("Success"), t(res.data.message), 'success');
				} else {
					Swal.fire(t("Error"), t(res.data.message), 'error');
				}
			}).catch(err => {
				dispatch({ type: 'LOADING_PROMOTION', payload: false });
				Swal.fire(t("Error"), t("An error occurred!"), 'error');
			});
		}
	})
};

const deletePromotion = (data) => (dispatch, getState) => {
	var body = {
		_id: data._id
	};
	Swal.fire({
		title: t("Are you sure?"),
		text: t("Are you sure to delete promotion?"),
		icon: 'warning',
		showCancelButton: true,
		confirmButtonColor: '#3085d6',
		cancelButtonColor: '#d33',
		confirmButtonText: t("Sure"),
		cancelButtonText: t("Cancel")
	}).then((result) => {
		if (result.value) {
			dispatch({ type: 'LOADING_PROMOTION', payload: true });
			axios.post('/api/promotion/delete', body, tokenConfig(getState)).then(res => {
				dispatch({ type: 'LOADING_PROMOTION', payload: false });
				if (res.data.status === 200) {
					dispatch(getListPromotions(data.page, data.sizePerPage, data.searchText));
					Swal.fire(t("Success"), t(res.data.message), 'success');
				} else {
					Swal.fire(t("Error"), t(res.data.message), 'error');
				}
			}).catch(err => {
				dispatch({ type: 'LOADING_PROMOTION', payload: false });
				Swal.fire(t("Error"), t("An error occurred!"), 'error');
			});
		}
	})
};

const upPromotion = (data) => (dispatch, getState) => {
	Swal.fire({
		title: t("Are you sure?"),
		text: t("Are you sure to start up promotion?"),
		icon: 'warning',
		showCancelButton: true,
		confirmButtonColor: '#3085d6',
		cancelButtonColor: '#d33',
		confirmButtonText: t("Sure"),
		cancelButtonText: t("Cancel")
	}).then(async (result) => {
		if (result.value) {
			var res_data = await dispatch(getProductFromProductTiktok(data));
			dispatch({ type: 'LOADING_PROMOTION', payload: true });
			var logs_arr = [];
			var successfully = 0;
			var failed = 0;
			var count_done = 0;
			if (res_data?.successfully) {
				res_data.logs_arr?.map((v, i) => {
					logs_arr.push(v);
					return i;
				})
				successfully += +res_data.successfully;
				failed += +res_data.failed;
				count_done += +res_data.successfully;
				dispatch({ type: 'UP_PROMOTION_PERCENT', payload: { percent: Math.ceil(count_done / +logs_arr.length * 100) } });
				let body_get = { page: 1, sizeperpage: 10, promotion_selected: data.promotion_selected, shops_selected: data.shops_selected };
				if (data.searchText !== "") {
					body_get = {
						...body_get,
						search: data.searchText
					};
				}
				var filter_tmp1 = new URLSearchParams(data.filter).toString();
				if (filter_tmp1) {
					body_get = {
						...body_get,
						...data.filter
					};
				}
				dispatch(getProductsByShops(body_get));
				dispatch({ type: 'UP_PROMOTION_LOGS', payload: { logs_arr, successfully, failed } });
				data.setProductsSelected([]);
				dispatch({ type: 'LOADING_PROMOTION', payload: false });
			} else {
				dispatch({ type: 'LOADING_PROMOTION', payload: true });
				let body_get = { page: 1, sizeperpage: 10, promotion_selected: data.promotion_selected, shops_selected: data.shops_selected };
				if (data.searchText !== "") {
					body_get = {
						...body_get,
						search: data.searchText
					};
				}
				var filter_tmp2 = new URLSearchParams(data.filter).toString();
				if (filter_tmp2) {
					body_get = {
						...body_get,
						...data.filter
					};
				}
				dispatch(getProductsByShops(body_get));
				dispatch({ type: 'UP_PROMOTION_LOGS', payload: { logs_arr, successfully, failed } });
				data.setProductsSelected([]);
				dispatch({ type: 'LOADING_PROMOTION', payload: false });
			}
			// if (products_selected_api.length > 0) {
			// 	var group_by_shop_id = _.groupBy(products_selected_api, 'shopId');
			// 	// var chunk_group_up = Object.keys(group_by_shop_id).map((k_group_by_shop_id) => {
			// 	// 	var chunk_by_app_name = 1;
			// 	// 	// if (group_by_shop_id[k_group_by_shop_id]?.[0].apps.name !== "APP OK") {
			// 	// 	// 	chunk_by_app_name = 1;
			// 	// 	// }
			// 	// 	return _.chunk(group_by_shop_id[k_group_by_shop_id], chunk_by_app_name);
			// 	// });


			// 	// await Promise.all(chunk_group_up.map(async (v_chunk_group_up, i_chunk_group_up) => {
			// 	// 	return new Promise(async (resolve, reject) => {
			// 	// 		var chunk_v_chunk_group_up = _.chunk(v_chunk_group_up, 30);
			// 	// 		for (var n = 0; n < chunk_v_chunk_group_up.length; n++) {
			// 	// 			var arr_v_chunk_group_up = chunk_v_chunk_group_up[n];
			// 	// 			await Promise.all(arr_v_chunk_group_up.map((v_v_chunk_group_up, i_i_chunk_group_up) => {
			// 	// 				return new Promise(async (resolves, rejects) => {
			// 	// 					await sleep(i_i_chunk_group_up * 100);
			// 	// 					var body = {
			// 	// 						list_products: v_v_chunk_group_up.map((v) => ({ product_tiktok_id: v.apiProductId, shop_id: v.shopId, promotion_id: data.promotion_selected, skus: v.skus, price: v.price, title: v.title }))
			// 	// 					};
			// 	// 					await axios.post('/api/promotion/up', body, tokenConfig(getState)).then(res => {
			// 	// 						if (res.data.status === 200) {
			// 	// 							logs_arr.push(res.data.data.logs_arr?.[0]);
			// 	// 							successfully += +res.data.data.successfully;
			// 	// 							failed += +res.data.data.failed;
			// 	// 							count_done += +v_v_chunk_group_up.length;
			// 	// 							dispatch({ type: 'UP_PROMOTION_PERCENT', payload: { percent: Math.ceil(count_done / +products_selected_api.length * 100) } });
			// 	// 							resolves("");
			// 	// 						} else {
			// 	// 							resolves("");
			// 	// 						}
			// 	// 					}).catch(err => {
			// 	// 						resolves("");
			// 	// 					});
			// 	// 				});
			// 	// 			}))
			// 	// 		}
			// 	// 		resolve();
			// 	// 	});
			// 	// })).then(async (dat) => {
			// 	// 	var body_get = { page: 1, sizeperpage: 10, promotion_selected: data.promotion_selected, shops_selected: data.shops_selected };
			// 	// 	if (data.searchText !== "") {
			// 	// 		body_get = {
			// 	// 			...body_get,
			// 	// 			search: data.searchText
			// 	// 		};
			// 	// 	}
			// 	// 	dispatch(getProductsByShops(body_get));
			// 	// 	dispatch({ type: 'UP_PROMOTION_LOGS', payload: { logs_arr, successfully, failed } });
			// 	// 	data.setProductsSelected([]);
			// 	// 	dispatch({ type: 'LOADING_PROMOTION', payload: false });
			// 	// })
			// 	// await Promise.all(Object?.values(group_by_shop_id)?.map(async (group_up, i_group_up) => {
			// 	// 	return new Promise(async (resolve, reject) => {
			// 	// 		var shopId = group_up[0].shopId;
			// 	// 		var promotion_id = data.promotion_selected;
			// 	// 		await axios.post('/api/promotion/up2', { data: group_up, shopId, promotion_id }, tokenConfig(getState)).then(res => {
			// 	// 			if (res.data.status === 200) {
			// 	// 				res.data.data.logs_arr?.map((v,i) => {
			// 	// 					logs_arr.push(v);
			// 	// 					return i;
			// 	// 				})
			// 	// 				successfully += +res.data.data.successfully;
			// 	// 				failed += +res.data.data.failed;
			// 	// 				count_done += +group_up.length;
			// 	// 				dispatch({ type: 'UP_PROMOTION_PERCENT', payload: { percent: Math.ceil(count_done / +products_selected_api.length * 100) } });
			// 	// 				resolve("");
			// 	// 			} else {
			// 	// 				resolve("");
			// 	// 			}
			// 	// 		}).catch(err => {
			// 	// 			resolve("");
			// 	// 		});
			// 	// 	});
			// 	// })).then(async (dat) => {
			// 	dispatch({ type: 'LOADING_PROMOTION', payload: true });
			// 	// 	var body_get = { page: 1, sizeperpage: 10, promotion_selected: data.promotion_selected, shops_selected: data.shops_selected };
			// 	// 	if (data.searchText !== "") {
			// 	// 		body_get = {
			// 	// 			...body_get,
			// 	// 			search: data.searchText
			// 	// 		};
			// 	// 	}
			// 	// 	dispatch(getProductsByShops(body_get));
			// 	// 	dispatch({ type: 'UP_PROMOTION_LOGS', payload: { logs_arr, successfully, failed } });
			// 	// 	data.setProductsSelected([]);
			// 	// 	dispatch({ type: 'LOADING_PROMOTION', payload: false });
			// 	// })
			// } else {
			// 	dispatch({ type: 'LOADING_PROMOTION', payload: true });
			// 	var body_get = { page: 1, sizeperpage: 10, promotion_selected: data.promotion_selected, shops_selected: data.shops_selected };
			// 	if (data.searchText !== "") {
			// 		body_get = {
			// 			...body_get,
			// 			search: data.searchText
			// 		};
			// 	}
			// 	dispatch(getProductsByShops(body_get));
			// 	dispatch({ type: 'UP_PROMOTION_LOGS', payload: { logs_arr, successfully, failed } });
			// 	data.setProductsSelected([]);
			// 	dispatch({ type: 'LOADING_PROMOTION', payload: false });
			// }
		}
	})
};

const getListPromotionsProductsTikTok = (page = 0, sizeperpage = 0, search = "", filter = "", filter2 = "") => (dispatch, getState) => {
	dispatch({ type: 'LOADING_PROMOTION', payload: true });
	var param_search = "";
	if (search !== "") {
		param_search = `&search=${search}`;
	}
	var param_filter = "";
	if (filter !== "") {
		param_filter = `&filter=${filter}`;
	}
	var param_filter2 = "";
	if (filter2 !== "") {
		param_filter2 = `&filter2=${filter2}`;
	}
	axios.get(`/api/promotion/list-promotion-product-tiktok?page=${page}&sizeperpage=${sizeperpage}${param_search}${param_filter}${param_filter2}`, tokenConfig(getState)).then(res => {
		dispatch({ type: 'LOADING_PROMOTION', payload: false });
		if (res.data.status === 200) {
			dispatch({
				type: 'LIST_PROMOTION_PRODUCT_TIKTOK',
				payload: res.data.data
			});
		} else {
			Swal.fire(t("Error"), t(res.data.message), 'error');
		}
	}).catch(err => {
		dispatch({ type: 'LOADING_PROMOTION', payload: false });
		Swal.fire(t("Error"), t("An error occurred!"), 'error');
	});
};

const extendPromotion = (data) => (dispatch, getState) => {
	Swal.fire({
		title: t("Are you sure?"),
		text: t("Are you sure to extend promotion?"),
		icon: 'warning',
		showCancelButton: true,
		confirmButtonColor: '#3085d6',
		cancelButtonColor: '#d33',
		confirmButtonText: t("Sure"),
		cancelButtonText: t("Cancel")
	}).then(async (result) => {
		if (result.value) {
			var promotionproducttiktoks_selected_api = await dispatch(getPromotionProductTiktok(data));
			dispatch({ type: 'LOADING_PROMOTION', payload: true });

			var group_by_shop_id = _.groupBy(promotionproducttiktoks_selected_api, 'shop_id');

			var group_by_activity_id = Object.keys(group_by_shop_id).map((k_group_by_shop_id) => _.groupBy(group_by_shop_id[k_group_by_shop_id], 'activity_id'));
			var chunk_group_remove = group_by_activity_id.map((v_group_by_activity_id) => {
				return Object.keys(v_group_by_activity_id).map((v) => _.chunk(v_group_by_activity_id[v], 300));
			});
			var remove_shop_promise = chunk_group_remove.map(async (v_chunk_group_remove, i_chunk_group_remove) => {
				return new Promise(async (resolve, reject) => {
					var remove_chunk_promise = _.flatten(v_chunk_group_remove).map((v_v_chunk_group_remove, i_i_chunk_group_remove) => {
						return new Promise(async (resolve, reject) => {
							setTimeout(() => {
								var body = {
									list_promotionproductiktok: v_v_chunk_group_remove.map((v) => ({ product_tiktok_id: v.product_tiktok_id, shop_id: v.shop_id, activity_id: v.activity_id }))
								};
								axios.post('/api/promotion/remove', body, tokenConfig(getState)).then(res => {
									if (res.data.status === 200) {
										resolve("");
									} else {
										resolve("");
									}
								}).catch(err => {
									resolve("");
								});
							}, i_i_chunk_group_remove * 2000);
						});
					});
					resolve(await Promise.all(remove_chunk_promise));
				});
			});
			await Promise.all(remove_shop_promise);

			var logs_arr = [];
			var successfully = 0;
			var failed = 0;
			var count_done = 0;

			var group_by_promotion_id = Object.keys(group_by_shop_id).map((k_group_by_shop_id) => _.groupBy(group_by_shop_id[k_group_by_shop_id], 'promotion_id'));
			var chunk_group_up = group_by_promotion_id.map((v_group_by_promotion_id) => {
				return Object.keys(v_group_by_promotion_id).map((v) => {
					var chunk_by_app_name = 1;
					// if (v_group_by_promotion_id[v]?.[0].apps.name !== "APP OK") {
					// 	chunk_by_app_name = 1;
					// }
					return _.chunk(v_group_by_promotion_id[v], chunk_by_app_name);
				});
			});
			var up_shop_promise = chunk_group_up.map(async (v_chunk_group_up, i_chunk_group_up) => {
				return new Promise(async (resolve, reject) => {
					var up_chunk_promise = _.flatten(v_chunk_group_up).map((v_v_chunk_group_up, i_i_chunk_group_up) => {
						return new Promise(async (resolve, reject) => {
							setTimeout(() => {
								var body = {
									list_products: v_v_chunk_group_up.map((v) => ({ product_tiktok_id: v.product_tiktok_id, shop_id: v.shop_id, promotion_id: v.promotion_id, skus: v.producttiktoks.skus, price: v.producttiktoks.price, title: v.producttiktoks.title }))
								};
								axios.post('/api/promotion/up', body, tokenConfig(getState)).then(res => {
									if (res.data.status === 200) {
										logs_arr.push(res.data.data.logs_arr?.[0]);
										successfully += +res.data.data.successfully;
										failed += +res.data.data.failed;
										count_done += +v_v_chunk_group_up.length;
										dispatch({ type: 'UP_PROMOTION_PERCENT', payload: { percent: Math.ceil(count_done / +promotionproducttiktoks_selected_api.length * 100) } });
										resolve("");
									} else {
										resolve("");
									}
								}).catch(err => {
									resolve("");
								});
							}, i_i_chunk_group_up * 1750);
						});
					});
					resolve(await Promise.all(up_chunk_promise));
				});
			});
			await Promise.all(up_shop_promise);

			dispatch(getListPromotionsProductsTikTok(data.page, data.sizePerPage, data.searchText, data.filterShop, data.filterTeam));
			dispatch({ type: 'UP_PROMOTION_LOGS', payload: { logs_arr, successfully, failed } });
			data.setCheckAllPromotionProductTiktoks(false);
			dispatch({ type: 'LOADING_PROMOTION', payload: false });
			Swal.fire(t("Success"), t("Extend promotion successfully!"), 'success');
		}
	})
};

const deactivatePromotion = (data) => (dispatch, getState) => {
	Swal.fire({
		title: t("Are you sure?"),
		text: t("Are you sure to deactivate all promotion?"),
		icon: 'warning',
		showCancelButton: true,
		confirmButtonColor: '#3085d6',
		cancelButtonColor: '#d33',
		confirmButtonText: t("Sure"),
		cancelButtonText: t("Cancel")
	}).then(async (result) => {
		if (result.value) {
			dispatch({ type: 'LOADING_PROMOTION', payload: true });

			await axios.post('/api/promotion/check-deactivate', data, tokenConfig(getState)).then(async res => {
				if (res.data.status === 200) {
					Promise.all(
						res?.data?.data?.shops?.map(async (data, i_app) => {
							return new Promise(async (resolve2, reject2) => {
								for (let i = 0; i < data?.length; i++) {
									var shopId = data[i]._id;
									var appId = data[i].appId;
									await deactivatePromotionApi(getState, shopId, appId, data.type).then(async (a) => await sleep(500))
								}
								resolve2('')
							});
						})
					).then(async (dat) => {
						dispatch(getProductsByShops({ page: 1, sizeperpage: 10, promotion_selected: data.promotion_selected, shops_selected: data.filterShop.split(",") }));
						Swal.fire(t("Success"), t("Deleted success"), "success");
					});
				}
			}).catch(err => {
				Swal.fire(t("Error"), err, "error");
			});

			// var type = data.type;
			// var list_shops = data.filterShop.split(",");
			// var deactivate_promise = list_shops.map((v_list_shops, i_list_shops) => {
			// 	return deactivateAllPromotionApi(getState, v_list_shops, i_list_shops, "", type);
			// });
			// await Promise.all(deactivate_promise);



		}
	})
};

const deactivatePromotionApi = (getState, shopId, appId, type = "limit", next_page_token = "") => {
	return new Promise(async (resolve, reject) => {
		await axios.post('/api/promotion/deactivate', { shopId, appId, type }, tokenConfig(getState)).then(async res => {
			if (res.data.status === 200) {
				if (res.data.data.next_page_token !== "") {
					await sleep(200)
					await deactivatePromotionApi(getState, shopId, appId, type, res.data.data.next_page_token).then(async (a) => resolve(a))

				} else {
					resolve("");
				}
			} else {
				resolve("");
			}
		}).catch(err => {
			resolve("");
		});
	});
};

const deactivateAllPromotion = (data) => (dispatch, getState) => {
	Swal.fire({
		title: t("Are you sure?"),
		text: t("Are you sure to deactivate all promotion?"),
		icon: 'warning',
		showCancelButton: true,
		confirmButtonColor: '#3085d6',
		cancelButtonColor: '#d33',
		confirmButtonText: t("Sure"),
		cancelButtonText: t("Cancel")
	}).then(async (result) => {
		if (result.value) {
			dispatch({ type: 'LOADING_PROMOTION', payload: true });

			var type = data.type;
			var list_shops = data.filterShop.split(",");
			var deactivate_promise = list_shops.map((v_list_shops, i_list_shops) => {
				return deactivateAllPromotionApi(getState, v_list_shops, i_list_shops, "", type);
			});
			await Promise.all(deactivate_promise);

			dispatch(getProductsByShops({ page: 1, sizeperpage: 10, promotion_selected: data.promotion_selected, shops_selected: data.filterShop.split(",") }));
		}
	})
};

const deactivateAllPromotionApi = (getState, v_list_shops, i_list_shops, next_page_token = "", type = "limit") => {
	return new Promise(async (resolve, reject) => {
		console.log(type)
		setTimeout(() => {
			var body = {
				shop_id: v_list_shops,
				next_page_token,
				type
			};
			axios.post('/api/promotion/deactivate-all', body, tokenConfig(getState)).then(async res => {
				if (res.data.status === 200) {
					if (res.data.data.next_page_token !== "") {
						resolve(await deactivateAllPromotionApi(getState, v_list_shops, i_list_shops, res.data.data.next_page_token, type));
					} else {
						resolve("");
					}
				} else {
					resolve("");
				}
			}).catch(err => {
				resolve("");
			});
		}, i_list_shops * 1000);
	});
};

const removePromotion = (data) => (dispatch, getState) => {
	Swal.fire({
		title: t("Are you sure?"),
		text: t("Are you sure to extend promotion?"),
		icon: 'warning',
		showCancelButton: true,
		confirmButtonColor: '#3085d6',
		cancelButtonColor: '#d33',
		confirmButtonText: t("Sure"),
		cancelButtonText: t("Cancel")
	}).then(async (result) => {
		if (result.value) {
			var promotionproducttiktoks_selected_api = await dispatch(getPromotionProductTiktok(data));
			dispatch({ type: 'LOADING_PROMOTION', payload: true });

			var group_by_shop_id = _.groupBy(promotionproducttiktoks_selected_api, 'shop_id');

			var group_by_activity_id = Object.keys(group_by_shop_id).map((k_group_by_shop_id) => _.groupBy(group_by_shop_id[k_group_by_shop_id], 'activity_id'));
			var chunk_group_remove = group_by_activity_id.map((v_group_by_activity_id) => {
				return Object.keys(v_group_by_activity_id).map((v) => _.chunk(v_group_by_activity_id[v], 300));
			});
			var remove_shop_promise = chunk_group_remove.map(async (v_chunk_group_remove, i_chunk_group_remove) => {
				return new Promise(async (resolve, reject) => {
					var remove_chunk_promise = _.flatten(v_chunk_group_remove).map((v_v_chunk_group_remove, i_i_chunk_group_remove) => {
						return new Promise(async (resolve, reject) => {
							setTimeout(() => {
								var body = {
									list_promotionproductiktok: v_v_chunk_group_remove.map((v) => ({ product_tiktok_id: v.product_tiktok_id, shop_id: v.shop_id, activity_id: v.activity_id }))
								};
								axios.post('/api/promotion/remove', body, tokenConfig(getState)).then(res => {
									if (res.data.status === 200) {
										resolve("");
									} else {
										resolve("");
									}
								}).catch(err => {
									resolve("");
								});
							}, i_i_chunk_group_remove * 2000);
						});
					});
					resolve(await Promise.all(remove_chunk_promise));
				});
			});
			await Promise.all(remove_shop_promise);

			dispatch(getListPromotionsProductsTikTok(data.page, data.sizePerPage, data.searchText, data.filterShop, data.filterTeam));
			data.setCheckAllPromotionProductTiktoks(false);
			dispatch({ type: 'LOADING_PROMOTION', payload: false });
			Swal.fire(t("Success"), t("Remove promotion successfully!"), 'success');
		}
	})
};

const saveEditPromotion = (data, closemodal) => (dispatch, getState) => {
	var body = {
		_id: data._id,
		title: data.title,
		product_level: data.product_level,
		activity_type: data.activity_type,
		activity_price_amount_first_variant: data.activity_price_amount_first_variant,
		limit_first_varinat: data.limit_first_varinat,
		activity_price_amount: data.activity_price_amount,
		discount_period: data.discount_period,
		auto_renews: data.auto_renews,
		// begin_time: data.begin_time,
		// end_time: data.end_time
	};
	Swal.fire({
		title: t("Are you sure?"),
		text: t("Are you sure to edit promotion?"),
		icon: 'warning',
		showCancelButton: true,
		confirmButtonColor: '#3085d6',
		cancelButtonColor: '#d33',
		confirmButtonText: t("Sure"),
		cancelButtonText: t("Cancel")
	}).then((result) => {
		if (result.value) {
			dispatch({ type: 'LOADING_PROMOTION', payload: true });
			axios.post('/api/promotion/edit', body, tokenConfig(getState)).then(res => {
				dispatch({ type: 'LOADING_PROMOTION', payload: false });
				if (res.data.status === 200) {
					closemodal();
					dispatch(getListPromotions(data.page, data.sizePerPage, data.searchText));
					Swal.fire(t("Success"), t(res.data.message), 'success');
				} else {
					Swal.fire(t("Error"), t(res.data.message), 'error');
				}
			}).catch(err => {
				dispatch({ type: 'LOADING_PROMOTION', payload: false });
				Swal.fire(t("Error"), t("An error occurred!"), 'error');
			});
		}
	})
};

const getProductFromProductTiktok = (data) => (dispatch, getState) => {
	var body = {
		promotion_id: data.promotion_selected,
		shops_id_arr: data.shops_selected
	};
	if (data.products_selected !== "") {
		body = {
			...body,
			products_selected: data.products_selected
		};
	}
	if (data.searchText !== "") {
		body = {
			...body,
			search: data.searchText
		};
	}
	var filter = new URLSearchParams(data.filter).toString();
	if (filter) {
		body = {
			...body,
			...data.filter
		};
	}
	dispatch({ type: 'LOADING_PROMOTION', payload: true });
	return axios.post('/api/promotion/get-product-from-product-tiktok', body, tokenConfig(getState)).then(res => {
		dispatch({ type: 'LOADING_PROMOTION', payload: false });
		if (res.data.status === 200) {
			return res.data.data;
		} else {
			Swal.fire(t("Error"), t(res.data.message), 'error');
			return {};
		}
	}).catch(err => {
		dispatch({ type: 'LOADING_PROMOTION', payload: false });
		return [];
	});
};

const getPromotionProductTiktok = (data) => (dispatch, getState) => {
	var body = {};
	if (data.promotionproducttiktoks_selected !== "") {
		body = {
			...body,
			promotionproducttiktoks_selected: data.promotionproducttiktoks_selected
		};
	}
	if (data.searchText !== "") {
		body = {
			...body,
			search: data.searchText
		};
	}
	if (data.filterShop !== "") {
		body = {
			...body,
			filter: data.filterShop
		};
	}
	if (data.filterTeam !== "") {
		body = {
			...body,
			filter2: data.filterTeam
		};
	}
	dispatch({ type: 'LOADING_PROMOTION', payload: true });
	return axios.post('/api/promotion/get-promotion-product-tiktok', body, tokenConfig(getState)).then(res => {
		dispatch({ type: 'LOADING_PROMOTION', payload: false });
		if (res.data.status === 200) {
			return res.data.data.promotionproducttiktoks || [];
		}
	}).catch(err => {
		dispatch({ type: 'LOADING_PROMOTION', payload: false });
		return [];
	});
};

export {
	getListPromotions,
	saveAddPromotion,
	deletePromotion,
	upPromotion,
	getListPromotionsProductsTikTok,
	extendPromotion,
	deactivateAllPromotion,
	removePromotion,
	saveEditPromotion,
	getProductFromProductTiktok,
	getPromotionProductTiktok,
	deactivatePromotion
};
