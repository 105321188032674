import axios from "axios";
import Swal from "sweetalert2";
import { tokenConfig } from "./websiteActions";
import { t } from "i18next";

const getProductTikToks =
    ({ page = 0, sizeperpage = 0, search = "", filter = {}, sort_by = "", sort_order = "" } = {}) =>
        (dispatch, getState) => {
            dispatch({ type: "LOADING_PRODUCT_TIKTOK", payload: true });
            var param_search = "";
            if (search !== "") {
                param_search = `&search=${search}`;
            }
            filter = new URLSearchParams(filter).toString();
            if (filter) filter = `&${filter}`;

            var param_sort_by = "";
            if (sort_by !== "") {
                param_sort_by = `&sort_by=${sort_by}`;
            }

            var param_sort_order = "";
            if (sort_order !== "") {
                param_sort_order = `&sort_order=${sort_order}`;
            }

            axios
                .get(`/api/product-tiktok?page=${page}&sizeperpage=${sizeperpage}${param_search}${filter}${param_sort_by}${param_sort_order}`, tokenConfig(getState))
                .then((res) => {
                    dispatch({ type: "LOADING_PRODUCT_TIKTOK", payload: false });

                    if (res.data.status === 200) {
                        dispatch({
                            type: "ADMIN_LIST_PRODUCT_TIKTOK",
                            payload: res.data.data,
                        });
                    } else {
                        Swal.fire(t("Error"), t(res.data.message), "error");
                    }
                })
                .catch((err) => {
                    dispatch({ type: "LOADING_PRODUCT_TIKTOK", payload: false });
                    Swal.fire(t("Error"), t("An error occurred!"), "error");
                });
        };

const getProductTikToksFreeze =
    ({ page = 0, sizeperpage = 0, search = "", filter = {} } = {}) =>
        (dispatch, getState) => {
            dispatch({ type: "LOADING_PRODUCT_TIKTOK", payload: true });
            var param_search = "";
            if (search !== "") {
                param_search = `&search=${search}`;
            }
            filter = new URLSearchParams(filter).toString();
            if (filter) filter = `&${filter}`
            axios
                .get(`/api/product-tiktok/freeze?page=${page}&sizeperpage=${sizeperpage}${param_search}${filter}`, tokenConfig(getState))
                .then((res) => {
                    dispatch({ type: "LOADING_PRODUCT_TIKTOK", payload: false });

                    if (res.data.status === 200) {
                        dispatch({
                            type: "ADMIN_LIST_PRODUCT_FREEZE",
                            payload: res.data.data,
                        });
                    } else {
                        Swal.fire(t("Error"), t(res.data.message), "error");
                    }
                })
                .catch((err) => {
                    dispatch({ type: "LOADING_PRODUCT_TIKTOK", payload: false });
                    Swal.fire(t("Error"), t("An error occurred!"), "error");
                });
        };

const getCheckProductTikTok = (id) => (dispatch, getState) => {
    dispatch({ type: "LOADING_PRODUCT_TIKTOK", payload: true });
    return Swal.fire({
        title: t("Are you sure?"),
        text: t("Are you sure action?"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: t("Sure"),
        cancelButtonText: t("Cancel"),
    }).then(async (result) => {
        if (result.value) {
            return axios
                .post(`/api/product-tiktok/check`, { products: id }, tokenConfig(getState))
                .then((res) => res.data.data)
                .catch((err) => {
                    console.log(err);
                    Swal.fire(t("Error"), t("An error occurred!"), "error");
                    return false;
                });
        } else {
            dispatch({ type: "LOADING_PRODUCT_TIKTOK", payload: false });
        }
    });
};

const deleteProductTikTok = (data) => (dispatch, getState) => {
    return axios
        .post(`/api/product-tiktok/delete`, data, tokenConfig(getState))
        .then((res) => res.data.data)
        .catch((err) => {
            console.log(err);
            Swal.fire(t("Error"), t("An error occurred!"), "error");
            dispatch({ type: "LOADING_PRODUCT_TIKTOK", payload: false });
            return {};
        });
};

const editProductTikTok = (data) => (dispatch, getState) => {
    return axios
        .post(`/api/product-tiktok/edit`, data, tokenConfig(getState))
        .then((res) => res.data.data)
        .catch((err) => {
            console.log(err);
            Swal.fire(t("Error"), t("An error occurred!"), "error");
            dispatch({ type: "LOADING_PRODUCT_TIKTOK", payload: false });
            return {};
        });
};

const getProductTikTokMainImageByApiProductId = (apiProductId) => (dispatch, getState) => {
    return axios
        .post(`/api/product-tiktok/get-main-images`, { apiProductId }, tokenConfig(getState))
        .then((res) => res.data.data)
        .catch((err) => {
            return [];
        });
};

export {
    getProductTikToks,
    getCheckProductTikTok,
    deleteProductTikTok,
    editProductTikTok,
    getProductTikTokMainImageByApiProductId,
    getProductTikToksFreeze,
};
