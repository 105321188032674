import { io } from "socket.io-client";
import store from "store";

var URL = "http://localhost:5002";
if (process.env.NODE_ENV === "production") {
    URL = "https://cotik.app";
}
let socket = null;

const initSocket = () => {
    socket?.removeAllListeners();
    socket = io(URL, {
        autoConnect: false,
        transportOptions: {
            polling: {
                extraHeaders: {
                    "al-token": localStorage.getItem("token") || ""
                },
            },
        },
    });

    socket.on('connect', function() {
        store.dispatch({
            type: "SOCKET_ID",
            payload: socket.id
        });
    });

    
    socket.on('new_message', function(data) {
        console.log(data);
        store.dispatch({
            type: "APPEND_MESSAGE",
            payload: data.data,
        });
    });

    socket.on('new_conversation', function() {

    });

    socket.on('kick_user', function() {
        store.dispatch({
            type: "LOGOUT_SUCCESS",
        });
    });

    socket.connect();
}

export { initSocket, socket };
