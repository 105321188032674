import { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
// import { Link } from "react-router-dom";
// import PropTypes from "prop-types";
// import cx from "classnames";
import SortableList, { SortableItem } from "react-easy-sort";
import arrayMove from "array-move";
import { ReactTable } from "components/Table";
import { ModalChangeText, ModalTemplate, ModalAddImages } from "../modal";
import { countString } from "components/Function";
import { createProductsByTemplate } from "actions/productActions";

function Main({ createProductsByTemplate, user, countString }) {
    const [page, setPage] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(10);
    const { t } = useTranslation();
    const [list, setList] = useState([]);
    const [products_selected, setProductsSelected] = useState([]);
    var { limit_shop } = user.user;

    useEffect(() => {
        if (localStorage.getItem("extension_listamazon")) {
            setProductsSelected([]);
            setList(JSON.parse(localStorage.getItem("extension_listamazon")));
        }
    }, []);

    useEffect(() => {
        localStorage.setItem("extension_listamazon", JSON.stringify(list));
    }, [list]);

    const columns = [
        {
            dataField: "_id",
            text: "",
            sort: true,
            editable: false,
            formatter: (cell, row, rowIndex) => rowIndex + 1,
        },
        {
            dataField: "shop_name",
            text: t("Shop Name"),
            sort: true,
            editable: false,
        },
        {
            dataField: "product_id",
            text: t("Product ID"),
            sort: true,
            editable: false,
            formatter: (cell, row, rowIndex) => <Fragment>{cell}</Fragment>,
        },
        {
            dataField: "title",
            text: t("Title"),
            sort: true,
            classes: "al-cursor-pointer",
            formatter: (cell, row, rowIndex) => (
                <Fragment>
                    <div>
                        <span className={cell?.length < 140 && cell?.length > 40 ? "text-success" : "text-danger"}>
                            Total Title: {cell?.length} <span className="text-info">{countString(cell)}</span>
                        </span>
                    </div>
                    {cell} <i className="fa fa-pencil"></i>
                </Fragment>
            ),
        },
        {
            dataField: "images",
            text: t("Images"),
            sort: true,
            editable: false,
            formatter: (cell, row, rowIndex) => (
                <Fragment>
                    <SortableList
                        onSortEnd={(oldIndex, newIndex) => onSortEnd(oldIndex, newIndex, rowIndex)}
                        className="al-thumbsContainer"
                        draggedItemClassName="dragged"
                    >
                        {cell.map((v, i) => {
                            return (
                                <SortableItem key={i}>
                                    <div className="al-thumb">
                                        <div className="al-thumbInner position-relative">
                                            <button
                                                onClick={() => removeImageProduct(row.product_id, i)}
                                                type="button"
                                                className="btn-delete-image"
                                            >
                                                <i className="fa fa-trash"></i>
                                            </button>
                                            <img
                                                className="al-product-img al-cursor-move w-100 h-100"
                                                style={{ objectFit: "cover" }}
                                                src={v || require("components/assets/media/misc/image.png")}
                                                alt=""
                                                draggable="false"
                                                key={i}
                                            />
                                        </div>
                                    </div>
                                </SortableItem>
                            );
                        })}
                    </SortableList>
                </Fragment>
            ),
        },
        {
            dataField: "-",
            text: t("Actions"),
            editable: false,
            formatter: (cell, row, rowIndex, formatExtraData) => (
                <>
                    <ModalAddImages onSubmit={handleAddImages} rowIndex={rowIndex} />
                    <button
                        className="btn btn-icon btn-bg-light btn-active-color-danger btn-sm"
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content={t("Delete")}
                        onClick={() => deleteProduct(rowIndex)}
                    >
                        <i className="ki-outline ki-trash fs-2"></i>
                    </button>
                </>
            ),
        },
    ];

    const onSortEnd = (oldIndex, newIndex, itemIndex) => {
        setList((prev) => {
            prev[itemIndex].images = arrayMove(prev[itemIndex]?.images || [], oldIndex, newIndex);
            return [...prev];
        });
    };

    const removeImageProduct = (productId, imageRemove) => {
        setList((prev) => {
            prev = prev.map((product) => {
                if (product.product_id === productId) {
                    const images = (product?.images || []).filter((_image, key) => key !== imageRemove);
                    return { ...product, images };
                }
                return { ...product };
            })
            
            return [...prev];
        });
    };

    const deleteProduct = (index) => {
        setList((prev) => [...prev?.filter((_v, i) => i !== index)]);
    };

    const deleteProducts = () => {
        setList((prev) => [...prev?.filter((v) => !products_selected.includes(v.product_id))]);
    };

    const removeSelect = (arr, value) => {
        var i = 0;
        while (i < arr.length) {
            if (arr[i] === value) {
                arr.splice(i, 1);
            } else {
                ++i;
            }
        }
        return arr;
    };

    const onSelect = (row, isSelect) => {
        var products_selected_tmp = products_selected;
        if (isSelect) {
            products_selected_tmp.push(row.product_id);
        } else {
            products_selected_tmp = removeSelect(products_selected_tmp, row.product_id);
        }
        var new_arr = JSON.stringify(products_selected_tmp);
        setProductsSelected(JSON.parse(new_arr));
    };

    const onSelectAll = (isSelect, row) => {
        var products_selected_tmp = products_selected;
        if (isSelect) {
            products_selected_tmp = row.map((v, i) => v.product_id);
        } else {
            products_selected_tmp = [];
        }
        setProductsSelected(products_selected_tmp);
    };

    const selectRow = {
        mode: "checkbox",
        clickToSelect: false,
        selected: products_selected,
        onSelect: onSelect,
        onSelectAll: onSelectAll,
    };

    const groupButton = () => {
        return <div className="mb-5"></div>;
    };

    const handleTableChange = (type, { page, sizePerPage, searchText }) => {
        if (type === "search") {
            setPage(1);
            setSizePerPage(50);
        } else {
            setPage(page);
            setSizePerPage(sizePerPage);
        }
    };

    const handleChangeText = (data) => {
        setList((prev) => {
            prev = prev.map((item) => {
                if (!products_selected.includes(item.product_id)) return item;
                let title = item?.title;
                if (data?.textNew && data?.textOld) {
                    title = title?.replace(new RegExp(data?.textOld, "gi"), data?.textNew);
                }

                if (data?.textStart) title = `${data?.textStart} ${title}`;
                if (data?.textEnd) title = `${title} ${data?.textEnd}`;

                return { ...item, title };
            });
            return prev;
        });
    };

    const handleAddImages = (rowIndex, data) => {
        setList((prev) => {
            prev = prev.map((item, i) => {
                if (i !== rowIndex) return item;
                let images = item?.images;
                if (data.length > 0) {
                    images = [...images, ...data];
                }

                return { ...item, images };
            });
            return prev;
        });
    };

    const uploadProduct = (templateId, otherImage) => {
        createProductsByTemplate({
            templateId,
            data: list.reduce((result, item) => {
                if (products_selected.includes(item.product_id)) {
                    const itemAdd = { ...item, main_images: item.images };
                    if (otherImage?.length) itemAdd.main_images = [...(itemAdd.main_images || []), ...otherImage];
                    result.push(itemAdd);
                }
                return result;
            }, []),
        });
    };

    const handleTableSaveCell = (oldValue, newValue, row, column) => {
        row[column.dataField] = newValue;
        setList((prev) => {
            const data = prev.map((product) => {
                if (product.product_id === row.product_id) {
                    return { ...product, [column.dataField]: newValue };
                }
                return { ...product };
            });
            return [...data];
        });
    };

    const sizePerPageList = [
        {
            text: '10', value: 10
        },
        {
            text: '50', value: 50
        },
        {
            text: '100', value: 100
        },
        {
            text: '150', value: 150
        },
        {
            text: '200', value: 200
        },
        {
            text: 'All', value: list.length||50
        }
    ];

    return (
        <Fragment>
            <div className="d-flex gap-4">
                {
                    limit_shop >= 10 ?
                        <Fragment>
                            <ModalChangeText onSubmit={handleChangeText} />
                            <ModalTemplate onSubmit={uploadProduct} />
                        </Fragment>
                    : ""
                }
                <button onClick={deleteProducts} type="button" className="btn btn-danger btn-sm mr-1">
                    {t("Delete Products")}
                </button>
            </div>
            <ReactTable
                keyField="product_id"
                columns={columns}
                data={list}
                groupButton={groupButton}
                handleTableChange={handleTableChange}
                page={page}
                sizePerPage={sizePerPage}
                totalSize={list.length}
                alClassName="table-layout-inherit"
                remote={{ search: false, pagination: false, sort: false }}
                isSelectRow={true}
                selectRow={selectRow}
                isCellEdit={true}
                handleBeforeSaveCell={handleTableSaveCell}
                sizePerPageList={sizePerPageList}
            />
        </Fragment>
    );
}

const mapStateToProps = (state) => ({
    user: state.user
});
const mapDispatchToProps = { createProductsByTemplate, countString };

export default connect(mapStateToProps, mapDispatchToProps)(Main);
