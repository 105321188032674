import { Fragment, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { addApp } from "actions/appActions";
import Loading from "components/Loading";
import {
    Modal,
    ModalBody,
    ModalFooter
} from "reactstrap";

function ModalAddApp(props) {
    const [name, setName] = useState("");
    const [app_id, setAppID] = useState("");
    const [app_key, setAppKey] = useState("");
    const [app_secret, setAppSecret] = useState("");
    const [redirect_url, setRedirectUrl] = useState("");
    const [webhook_url, setWebhookUrl] = useState("");
    const [app_url, setAppURL] = useState("");
    const [authorization_link, setAuthorizationLink] = useState("");
    const [target, setTarget] = useState("");
    var { isLoading } = props.app;
    var { isOpenModalAddApp, openModal, addApp } = props;
    const { t } = useTranslation();

    const onClickSaveAddApp = () => {
        addApp({ name, app_id, app_key, app_secret, redirect_url, webhook_url, app_url, authorization_link, target }, openModal);
    };

    const onChange = (e) => {
        if (e.target.name === "name") {
            setName(e.target.value)
        }
        if (e.target.name === "app_id") {
            setAppID(e.target.value);
        }
        if (e.target.name === "app_key") {
            setAppKey(e.target.value);
        }
        if (e.target.name === "app_secret") {
            setAppSecret(e.target.value);
        }
        if (e.target.name === "redirect_url") {
            setRedirectUrl(e.target.value);
        }
        if (e.target.name === "webhook_url") {
            setWebhookUrl(e.target.value);
        }
        if (e.target.name === "app_url") {
            setAppURL(e.target.value);
        }
        if (e.target.name === "authorization_link") {
            setAuthorizationLink(e.target.value);
        }
        if (e.target.name === "target") {
            setTarget(e.target.value)
        }
    }

    const onOpenModal = () => {
        openModal();
    };

    return (
        <Fragment>
            <Modal centered={true} size="lg" toggle={() => { onOpenModal() }} isOpen={isOpenModalAddApp}>
                <Loading isLoading={isLoading} />
                <div className="modal-header">
                    <h1 className="mb-0 text-dark">{t("Add App")}</h1>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                        <i className="ki-outline ki-cross fs-1" onClick={() => { onOpenModal() }} ></i>
                    </div>
                </div>
                <ModalBody>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Name")}</span>
                        </label>
                        <input type="text" className="form-control" value={name} placeholder={t("Name")} name="name" onChange={(e) => { onChange(e) }} />
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("App ID")}</span>
                        </label>
                        <input type="text" className="form-control" value={app_id} placeholder={t("App ID")} name="app_id" onChange={(e) => { onChange(e) }} />
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("App Key")}</span>
                        </label>
                        <input type="text" className="form-control" value={app_key} placeholder={t("App Key")} name="app_key" onChange={(e) => { onChange(e) }} />
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("App Secret")}</span>
                        </label>
                        <input type="text" className="form-control" value={app_secret} placeholder={t("App Secret")} name="app_secret" onChange={(e) => { onChange(e) }} />
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Redirect URL")}</span>
                        </label>
                        <input type="text" className="form-control" value={redirect_url} placeholder={t("Redirect URL")} name="redirect_url" onChange={(e) => { onChange(e) }} />
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Webhook URL")}</span>
                        </label>
                        <input type="text" className="form-control" value={webhook_url} placeholder={t("Webhook URL")} name="webhook_url" onChange={(e) => { onChange(e) }} />
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span>{t("App URL")}</span>
                        </label>
                        <input type="text" className="form-control" value={app_url} placeholder={t("App URL")} name="app_url" onChange={(e) => { onChange(e) }} />
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Authorization Link")}</span>
                        </label>
                        <input type="text" className="form-control" value={authorization_link} placeholder={t("Authorization Link")} name="authorization_link" onChange={(e) => { onChange(e) }} />
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Target")}</span>
                        </label>
                        <select className="form-select" data-control="select2" data-hide-search="true" data-placeholder="Select a Region Target" name="target" onChange={(e) => { onChange(e) }} >
                            <option value="">{t("-- Select a region target --")}</option>
                            <option value="US">United States</option>
                            <option value="VN">Viet Nam</option>
                            <option value="OTHER">Other</option>
                        </select>
                    </div>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("App Type")}</span>
                        </label>
                        <select className="form-select" data-control="select2" data-hide-search="true" data-placeholder="type" name="app_type" onChange={onChange} >
                            <option value="basic">Basic</option>
                            <option value="message">Message</option>
                        </select>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button type="reset" id="kt_modal_users_search_reset" data-bs-dismiss="modal" className="btn btn-active-light me-3" onClick={() => { onOpenModal() }} >{t("Cancel")}</button>
                    <button type="submit" id="kt_modal_users_search_submit" className="btn btn-primary" onClick={() => { onClickSaveAddApp() }}  >{t("Save")}</button>
                </ModalFooter>
            </Modal>


        </Fragment>
    );
}

ModalAddApp.propTypes = {
    addApp: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
    app: state.app
});
const mapDispatchToProps = {
    addApp
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalAddApp);