import { Fragment, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { saveEditOrder } from "actions/orderActions";
import Loading from "components/Loading";
import {
    Modal,
    ModalBody,
    ModalFooter
} from "reactstrap";

function Modaleditorderstatus(props) {
    const [_id] = useState(props.editorderstatus._id);
    const [order_status, setOrderStatus] = useState(props.editorderstatus.order_status);
    var { isLoading } = props.order;
    var { isOpenModalEditOrderStatus, openModal, saveEditOrder } = props;
	const { t } = useTranslation();

    const onClickSaveEditOrder = () => {
        saveEditOrder({ _id, order_status, page: props.editorderstatus.page, sizePerPage: props.editorderstatus.sizePerPage, searchText: props.editorderstatus.searchText, filter_printer: props.editorderstatus.filter_printer, filter_shop_code: props.editorderstatus.filter_shop_code, filter_status: props.editorderstatus.filter_status, filter_work_status: props.editorderstatus.filter_work_status }, openModal);
    };

    const onChange = (e) => {
        if (e.target.name === "order_status") {
            setOrderStatus(e.target.value);
        }
    }

    const onOpenModal = () => {
        openModal();
    };

    return (
        <Fragment>
            <Modal centered={true} size="lg" toggle={() => { onOpenModal() }} isOpen={isOpenModalEditOrderStatus}>
                <Loading isLoading={isLoading} />
                <div className="modal-header">
                    <h1 className="mb-0 text-dark">{t("Change Order Status")}</h1>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                        <i className="ki-outline ki-cross fs-1" onClick={() => { onOpenModal() }} ></i>
                    </div>
                </div>
                <ModalBody>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Order Status")}</span>
                        </label>
                        <select className="form-select" data-control="select2" data-hide-search="true" data-placeholder="Select a order status" value={order_status} name="order_status" onChange={(e) => { onChange(e) }} >
                            <option value="">{t("-- Select a order status --")}</option>
                            <option value="processing">{t("Processing")}</option>
                            <option value="working">{t("Working")}</option>
                            <option value="worked">{t("Worked")}</option>
                        </select>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button type="reset" id="kt_modal_users_search_reset" data-bs-dismiss="modal" className="btn btn-active-light me-3" onClick={() => { onOpenModal() }} >{t("Cancel")}</button>
                    <button type="submit" id="kt_modal_users_search_submit" className="btn btn-primary" onClick={() => { onClickSaveEditOrder() }}  >{t("Save")}</button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
}

Modaleditorderstatus.propTypes = {
    saveEditOrder: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
    order: state.order,
});
const mapDispatchToProps = {
    saveEditOrder,
}

export default connect(mapStateToProps, mapDispatchToProps)(Modaleditorderstatus);