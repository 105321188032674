import { Fragment } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";

function PrivacyPolicy(props) {
    var { pathname } = useLocation();

    return (
        <Fragment>
            <div
                id="kt_body"
                data-bs-spy="scroll"
                data-bs-target="#kt_landing_menu"
                className="bg-body position-relative"
            >
                <div className="d-flex flex-column flex-root">
                    <div className="mb-0" id="home">
                        <div className="bgi-no-repeat bgi-size-contain bgi-position-x-center bgi-position-y-bottom landing-dark-bg">
                            <div
                                className="landing-header"
                                data-kt-sticky="true"
                                data-kt-sticky-name="landing-header"
                                data-kt-sticky-offset="{default: '200px', lg: '300px'}"
                            >
                                <div className="container">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="d-flex align-items-center flex-equal">
                                            <button
                                                className="btn btn-icon btn-active-color-primary me-3 d-flex d-lg-none"
                                                id="kt_landing_menu_toggle"
                                            >
                                                <i className="ki-outline ki-abstract-14 fs-2hx"></i>
                                            </button>
                                            <a href="landing.html">
                                                <img
                                                    alt="Logo"
                                                    src={require("components/assets/media/custom/logo.png")}
                                                    className="logo-default h-60px h-lg-60px"
                                                />
                                                <img
                                                    alt="Logo"
                                                    src={require("components/assets/media/custom/logo.png")}
                                                    className="logo-sticky h-20px h-lg-25px"
                                                />
                                            </a>
                                        </div>
                                        <div className="d-lg-block" id="kt_header_nav_wrapper">
                                            <div
                                                className="d-lg-block p-5 p-lg-0"
                                                data-kt-drawer="true"
                                                data-kt-drawer-name="landing-menu"
                                                data-kt-drawer-activate="{default: true, lg: false}"
                                                data-kt-drawer-overlay="true"
                                                data-kt-drawer-width="200px"
                                                data-kt-drawer-direction="start"
                                                data-kt-drawer-toggle="#kt_landing_menu_toggle"
                                                data-kt-swapper="true"
                                                data-kt-swapper-mode="prepend"
                                                data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav_wrapper'}"
                                            >
                                                <div
                                                    className="menu menu-column flex-nowrap menu-rounded menu-lg-row menu-title-gray-600 menu-state-title-primary nav nav-flush fs-5 fw-semibold"
                                                    id="kt_landing_menu"
                                                >
                                                    <div className="menu-item">
                                                        <a
                                                            className="menu-link nav-link active py-3 px-4 px-xxl-6"
                                                            href="#kt_body"
                                                            data-kt-scroll-toggle="true"
                                                            data-kt-drawer-dismiss="true"
                                                        >
                                                            Home
                                                        </a>
                                                    </div>
                                                    <div className="menu-item">
                                                        <a
                                                            className="menu-link nav-link py-3 px-4 px-xxl-6"
                                                            href="#how-it-works"
                                                            data-kt-scroll-toggle="true"
                                                            data-kt-drawer-dismiss="true"
                                                        >
                                                            How it Works
                                                        </a>
                                                    </div>
                                                    <div className="menu-item">
                                                        <a
                                                            className="menu-link nav-link py-3 px-4 px-xxl-6"
                                                            href="#achievements"
                                                            data-kt-scroll-toggle="true"
                                                            data-kt-drawer-dismiss="true"
                                                        >
                                                            Achievements
                                                        </a>
                                                    </div>
                                                    <div className="menu-item">
                                                        <a
                                                            className="menu-link nav-link py-3 px-4 px-xxl-6"
                                                            href="/privacy-policy"
                                                            data-kt-scroll-toggle="true"
                                                            data-kt-drawer-dismiss="true"
                                                        >
                                                            Privacy Policy
                                                        </a>
                                                    </div>
                                                    <div className="menu-item">
                                                        <a
                                                            className="menu-link nav-link py-3 px-4 px-xxl-6"
                                                            href="#pricing"
                                                            data-kt-scroll-toggle="true"
                                                            data-kt-drawer-dismiss="true"
                                                        >
                                                            Pricing
                                                        </a>
                                                    </div>
                                                    <div className="menu-item">
                                                        <a
                                                            className="menu-link nav-link py-3 px-4 px-xxl-6"
                                                            href="#about-us"
                                                            data-kt-scroll-toggle="true"
                                                            data-kt-drawer-dismiss="true"
                                                        >
                                                            About Us
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex-equal text-end ms-1">
                                            <a
                                                href={
                                                    pathname.indexOf("/extension") > -1
                                                        ? "/admin/extension-etsy"
                                                        : "/admin"
                                                }
                                                className="btn btn-success"
                                            >
                                                Sign In
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="landing-curve landing-dark-color mb-10 mb-lg-20">
                            <svg viewBox="15 12 1470 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M0 11C3.93573 11.3356 7.85984 11.6689 11.7725 12H1488.16C1492.1 11.6689 1496.04 11.3356 1500 11V12H1488.16C913.668 60.3476 586.282 60.6117 11.7725 12H0V11Z"
                                    fill="currentColor"
                                ></path>
                            </svg>
                        </div>
                        <div className="mt-20 position-relative z-index-2">
                            <div className="container">
                                <div className="text-center mb-17">
                                    <h2
                                        className="fs-2hx text-gray-900 mb-5"
                                        id="clients"
                                        data-kt-scroll-offset="{default: 125, lg: 150}"
                                    >
                                        Privacy policy
                                    </h2>
                                    <div className="fs-5 text-muted fw-bold">
                                        We work hard to keep all our legal mumbo jumbo as simple as possible, but we
                                        <br />
                                        still have to have it.
                                    </div>
                                    <p className="text-muted ">Last updated September, 2024</p>
                                </div>
                                <div className="mx-15 mb-4">
                                    <div className="d-flex flex-column">
                                        <div className="mt-10">
                                            <p style={{ fontWeight: 600 }}>Privacy policy</p>
                                            <div className="separator separator-dashed my-4"></div>
                                            <p style={{ color: "#16263d" }}>
                                                Our Company, Net Ventures AS, operates https://cotik.app (the “Site”).
                                                This page informs you of our policies regarding the collection, use and
                                                disclosure of Personal Information we receive from users of the Site.
                                                This Privacy Policy governs our data collection, processing, and usage
                                                practices. It also describes your choices regarding use, access, and
                                                correction of your personal information. If you do not agree with the
                                                data practices described in this Privacy Policy, you should not use our
                                                Site and/or our Google Chrome extensions. We periodically update this
                                                Privacy Policy. We will post any privacy policy changes on this page
                                                and, if the changes are significant, we will provide a more prominent
                                                notice by sending you an email notification. While we will notify you of
                                                any material changes to this Privacy Policy, we encourage you to review
                                                this Privacy Policy periodically.
                                            </p>
                                        </div>
                                        <div className="mt-10">
                                            <p style={{ fontWeight: 600 }}>Information collection, duration & use</p>
                                            <div className="separator separator-dashed my-4"></div>
                                            <p style={{ color: "#16263d" }}>
                                                While using our Site, we may ask you to provide us with certain
                                                personally identifiable information that can be used to contact or
                                                identify you. Personally identifiable information may include, but is
                                                not limited to, the following: Your name, address, email address, and/or
                                                phone number. Information regarding your use of our App and/or Site, and
                                                which websites you came to our Site from. Technical information about
                                                your device or browser when you use our App and/or Site, including
                                                geolocation data to determine what country you are accessing our App
                                                and/or Site from, your internet protocol (IP) address, device ID,
                                                browser type and version, and time zone setting, which may in some
                                                circumstances be personal data. We may also receive personal data
                                                directly from you or from various third parties and public sources,
                                                including from social media channels (if you interact with us through
                                                those channels), or other third parties. For using the Cotik Chrome
                                                Extension.
                                            </p>
                                        </div>
                                        <div className="mt-10">
                                            <p style={{ fontWeight: 600 }}>Visitor consent</p>
                                            <div className="separator separator-dashed my-4"></div>
                                            <p style={{ color: "#16263d" }}>
                                                By visiting or using our Site and/or App, you consent to this Privacy
                                                Policy.
                                            </p>
                                        </div>
                                        <div className="mt-10">
                                            <p style={{ fontWeight: 600 }}>Cookie policy</p>
                                            <div className="separator separator-dashed my-4"></div>
                                            <p style={{ color: "#16263d" }}>
                                                Cookies are small bits of text data placed on your device when you visit
                                                sites. Cookies record data about your activity, such as which pages you
                                                view and what you click on. Cookies assist our Site to recognize your
                                                device when you return. For example, cookies can help us to remember
                                                your preferences, username, analyze the performance of our Site, and
                                                recommend content that may be most relevant to your interests.
                                                <br />
                                                <br />
                                                Here are the reasons we may use cookies:
                                                <br />
                                                <br />
                                                Analytics. This type of cookies shows us which pages users view, which
                                                links are popular, etc. These cookies only provide anonymized
                                                information that does not identify anybody personally. This information
                                                is then bundled with the similar information from the other users, so
                                                that we can analyze the general usage patterns.
                                                <br />
                                                <br />
                                                Essential cookies. These are necessary to provide the services that you
                                                have asked for. Without these essential cookies, our Site would not be
                                                able to operate. They are necessary to enable users to navigate through
                                                the Site and use its main features. E.g., essential cookies identify
                                                registered users so that they can access member-only areas of the site.
                                                Essential cookies keep users logged in. If a subscriber disables
                                                essential cookies, that subscriber will not be able to get to all of the
                                                content that a subscription entitles them to. These cookies do not
                                                collect information that could be utilized for the purposes of marketing
                                                or figuring out what places on the internet you have visited.
                                                <br />
                                                <br />
                                                To show relevant advertising. If we allow ads on our Site, they can
                                                contain cookies in order to provide ads most relevant to your interests.
                                                They can also help limit the number of times you see a particular ad and
                                                to assess the effectiveness of the advertising campaign.
                                                <br />
                                                <br />
                                                To improve your browsing experience. This type of cookies enables the
                                                site to remember users’ preferences and settings, such as geographic
                                                region or language. They can also be used to restrict the number of
                                                times an ad is shown, or to remember which forms you have already filled
                                                in so that you do not have to do it again.
                                                <br />
                                                <br />
                                                There is a way to turn off cookies by going to your browser’s Help or
                                                Settings menu. However, keep in mind that disabling cookies may limit
                                                your use of the Site and/or delay or affect the way in which it
                                                operates.
                                            </p>
                                        </div>
                                        <div className="mt-10">
                                            <p style={{ fontWeight: 600 }}>Contact us</p>
                                            <div className="separator separator-dashed my-4"></div>
                                            <p style={{ color: "#16263d" }}>
                                                Cotik welcomes your questions or comments regarding this Privacy Policy.
                                                If you believe that Cotik has not adhered to this Privacy Policy, please
                                                contact Cotik at: <br />
                                                <br />
                                                Email address: support@cotik.app
                                            </p>
                                        </div>

                                        <strong className="mt-10">EFFECTIVE AS OF December 2024</strong>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PrivacyPolicy);
